
import { autoinject } from "aurelia-framework";
import { Redirect } from "aurelia-router";

import { LoginContext } from '../models/LoginContext';
import { BaseClass } from '../core/base-class';

@autoinject()
export class AuthorizeStep extends BaseClass {
	//#region Variables

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext) {
		super('AuthorizeStep');
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------

	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  

	run(navigationInstruction, next) {

		if (navigationInstruction.getAllInstructions().some(i => i.config.settings.auth)) {
			//if user is not logged in redirect to login-view
			if (!this._loginContext.isAuth) {
				return next.cancel(this._router.navigateToRoute("login", this._urlParams));
			}
		}

		return next();
	}

	//#endregion Impl
}
