import { autoinject, DOM } from "aurelia-framework";

import { BaseClass } from '../../../core/base-class';
import { HistoryInput } from "../../../models/HistoryInput";
import { HistoryTextInput } from "../../../models/HistoryTextInput";
import { LoginContext } from '../../../models/LoginContext';
import { HistoryService } from "../../../services/project/history-service";

import './edit.scss';



@autoinject()
export class Edit extends BaseClass {
	//#region Variables
	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	private _indexOfEditedObj = null;
	private _historyTextInputList: HistoryTextInput[] = [];
	private _historyInput: HistoryInput;
	private _isLoading: boolean = true;
	private _pictureList: string[];
	private _selectedServerPictureList: any[] = [];
	private _selectedClientPictureList: any[] = [];

	
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext,
		private _historyService: HistoryService) {
		super('View-Edit_History');
		this._historyTextInputList.push(new HistoryTextInput("de"));
		this._historyTextInputList.push(new HistoryTextInput("en"));
		this._historyInput = new HistoryInput(this._historyTextInputList, null, null);
		this._historyService.GetPictureList().then((response) => {
			this._pictureList = response;
		});
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");
	}

	activate(params) {
		this._indexOfEditedObj = params.id;

		this._historyInput.TextItemList = [];
		this._historyInput.TextItemList.push(new HistoryTextInput("de"));
		this._historyInput.TextItemList.push(new HistoryTextInput("en"));
		this._historyInput = new HistoryInput(this._historyTextInputList, null, null);

		//if '_indexOfEditedObj' has an value, the user will edit an existing Object
		if (this._indexOfEditedObj != null) {
			this._isLoading = true;
			this._historyService.Get(this._indexOfEditedObj).then((resp) => {
				this._historyInput.imagePath = resp.ImagePath;
				this._selectedServerPictureList.push({
					index: 1,
					type: "server",
					data: this._historyInput.imagePath.substring(this._historyInput.imagePath.lastIndexOf("/") + 1)
				});
				//store current translation language
				var oldLocale = this._i18n.getLocale();
				for (var i = 0; i < this._historyInput.TextItemList.length; i++) {
					var lang = this._historyInput.TextItemList[i].language;
					// translation language
					this._i18n.setLocale(lang);
					//set title
					this._historyInput.TextItemList[i].title = this._i18n.tr("history.items." + resp.ID + ".title");
					//set description
					this._historyInput.TextItemList[i].description = this._i18n.tr("history.items." + resp.ID + ".description");
				}
				//set date
				this._historyInput.date = resp.Date;
				//set old translation language
				this._i18n.setLocale(oldLocale);
				this._isLoading = false;
			});
		} else {
			this._isLoading = false;
		}
	}

	attached() {
		super.attached();

		
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------

	private openPictureSelectWindow() {
		this.Publish("toggleOpen");
	}
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  

	redirect(newTab, newparams) {
		window.scrollTo(0, 0);
		this._router.navigateToRoute(newTab, newparams);
	}

	create() {
		var result = this.checkDataAndGenerateFormData();
		if (result != null) {
			this._historyService.Create(result).then((result) => {
				if (result) this.redirect("dashboard", { "pointer": "history" });
			});
		}
	}

	update() {
		var result = this.checkDataAndGenerateFormData();
		if (result != null) {
			this._historyService.Update(this._indexOfEditedObj, result).then((result) => {
				if (result) this.redirect("dashboard", { "pointer": "history" });
			});
		}
	}
	
	checkDataAndGenerateFormData() {
		//store new Product
		var newFormData = new FormData();
		for (var i = 0; i < this._selectedClientPictureList.length; i++) {
			newFormData.append("rawclientfiles", this._selectedClientPictureList[i].data);
		}
		newFormData.append("clientfilesJSON", JSON.stringify(this._selectedClientPictureList));
		newFormData.append("serverfilesJSON", JSON.stringify(this._selectedServerPictureList));
		newFormData.append("input", JSON.stringify(this._historyInput));
		return newFormData;
	}


    //#endregion Impl
}


