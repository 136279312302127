import { BrancheTextInput } from "./BrancheTextInput";


export class BrancheInput {
	TextItemList: BrancheTextInput[];
	ImagePaths: any[];
	VideoPath: any;
	OrderNumber: number;

	constructor(textInputList) {
		this.TextItemList = textInputList;
		this.ImagePaths = [];
		this.OrderNumber = -1;
	}
}