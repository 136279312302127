import { PressTextInput } from "./PressTextInput";

export class PressInput {
	TextItemList: PressTextInput[];
	videoPath: string;
	date: Date;

	constructor(textInputList, videoPath, date) {
		this.TextItemList = textInputList;
		this.videoPath = videoPath;
		this.date = date;
	}
}