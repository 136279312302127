import * as moment from 'moment';

export class DatetimeFormatValueConverter {
	signals = ['update-view'];

	toView(value, format) {
		return moment(value).format(format);
	}
}

