import { CompetenceTextInput } from "./CompetenceTextInput";


export class CompetenceInput {
	TextItemList: CompetenceTextInput[];
	ImagePath: any;
	OrderNumber: number;

	constructor(textInputList, imagePath) {
		this.TextItemList = textInputList;
		this.ImagePath = imagePath;
		this.OrderNumber = -1;
	}
}