import { TaskQueue, autoinject, DOM } from "aurelia-framework";
import Fuse from "fuse.js";
import i18next from "i18next";

import { BaseClass } from '../../../core/base-class';
import { deepCopy } from "../../../core/helper_classes/deepcopy/deepcopy";
import { StringHelper } from "../../../core/helper_classes/stringhelper/stringhelper";
import { Triggers } from "../../../core/helper_classes/triggers/triggers";
import { EditJobOfferDetail } from "../../../models/EditJobOfferDetail";
import { FontAwesomeHelper } from "../../../models/FontAwesomeArray";
import { JobOfferDetail } from "../../../models/JobOfferDetail";
import { JobOfferInput } from "../../../models/JobOfferInput";
import { JobOfferTextInput } from "../../../models/JobOfferTextInput";
import { LoginContext } from '../../../models/LoginContext';
import { JobOfferService } from "../../../services/project/joboffer-service";

import './edit.scss';



@autoinject()
export class Edit extends BaseClass {
	//#region Variables

	private readonly InitIcon = "fas fa-question";

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	private _indexOfEditedObj = null;
	private _isLoading: boolean = true;
	private _jobOfferTextInput: JobOfferTextInput[];
	private _jobOfferInput: JobOfferInput;
	private _editwindow;
	private _editActions = ['Close'];
	private _editTitle = "Add Detail";
	private _solidIconsArr = FontAwesomeHelper.solid;
	private _showIcons = FontAwesomeHelper.solid;
	private _iconname;
	private _selectIcon = false;
	private _editJobOfferDetail = new EditJobOfferDetail(this.InitIcon);
	private _editType = null;
	private _editIndex = null;
	private filterIconText = "";
	private filterIconEmpty = false;
	private _filterIconTimer = null;
	private _pictureList: string[];
	private _selectedServerPictureList: any[] = [];
	private _selectedClientPictureList: any[] = [];
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext,
				private _taskQueue: TaskQueue,
				private _jobOfferService: JobOfferService) {
		super('View-Edit_JobOffer');
		this._jobOfferService.GetPictureList().then((response) => {
			this._pictureList = response;
		});
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");
		
	}

	attached() {
		super.attached();

		this.setNewIcon(this.InitIcon);

		this._taskQueue.queueTask(() => {
			this._editwindow.center().close();
		});
	}

	activate(params) {
		this._indexOfEditedObj = params.id;

		this._jobOfferTextInput = [];
		this._jobOfferTextInput.push(new JobOfferTextInput("de"));
		this._jobOfferTextInput.push(new JobOfferTextInput("en"));
		this._jobOfferInput = new JobOfferInput(this._jobOfferTextInput, null, true);

		//if '_indexOfJobOffer' has an value, the user will edit an existing JobOffer
		if (this._indexOfEditedObj != null) {
			this._isLoading = true;
			this._jobOfferService.Get(this._indexOfEditedObj).then((resp) => {
				this._jobOfferInput.imagePath = resp.ImagePath;
				//adding selected image to imagepicker and select the right image
				this._selectedServerPictureList.push({
					index: 1,
					type: "server",
					data: this._jobOfferInput.imagePath.substring(this._jobOfferInput.imagePath.lastIndexOf("/") + 1)
				});
				//store current translation language
				var oldLocale = this._i18n.getLocale();
				for (var i = 0; i < this._jobOfferInput.TextItemList.length; i++) {
					var lang = this._jobOfferInput.TextItemList[i].language;
					// translation language
					this._i18n.setLocale(lang);
					//set title
					this._jobOfferInput.TextItemList[i].title = this._i18n.tr("career.joboffers." + resp.ID + ".title");
					//set knowledges
					resp.Knowledges.forEach((item) => {
						this._jobOfferInput.TextItemList[i].knowledges.push(new JobOfferDetail(
							item.IconClassname,
							this._i18n.tr("career.joboffers." + item.JobOfferID + ".knowledges." + item.ID + ".text"),
							this._i18n.tr("career.joboffers." + item.JobOfferID + ".knowledges." + item.ID + ".subtext"),
						));
					});
					//set tasks
					resp.Tasks.forEach((item) => {
						this._jobOfferInput.TextItemList[i].tasks.push(new JobOfferDetail(
							item.IconClassname,
							this._i18n.tr("career.joboffers." + item.JobOfferID + ".tasks." + item.ID + ".text"),
							this._i18n.tr("career.joboffers." + item.JobOfferID + ".tasks." + item.ID + ".subtext"),
						));
					});
					//set offers
					resp.Offers.forEach((item) => {
						this._jobOfferInput.TextItemList[i].offers.push(new JobOfferDetail(
							item.IconClassname,
							this._i18n.tr("career.joboffers." + item.JobOfferID + ".offers." + item.ID + ".text"),
							this._i18n.tr("career.joboffers." + item.JobOfferID + ".offers." + item.ID + ".subtext"),
						));
					});
				}
				//set old translation language
				this._i18n.setLocale(oldLocale);
				this._isLoading = false;
			});
		} else {
			this._isLoading = false;
		}
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------

	private onEditClose() {
		//clear old entry after 500ms cause of the fadeOut of the window
		this.CallAfterUIUpdate(() => {
			this._selectIcon = false;
			this.setNewIcon(this.InitIcon);
			this._editJobOfferDetail = new EditJobOfferDetail(this.InitIcon);
			this._editIndex = null;
		}, 500);
	}

	private onEditReady(editwindow) {
		this._editwindow = editwindow;
		this._editwindow.close();
	}

	private OnOpenJobOfferEdit(type = null) {
		this._editType = type;

		if (this._editwindow.options.visible) {
			this._editwindow.close();
		} else {
			this._editwindow.center();
			this._editwindow.open();
		}
	}

	private OnIconStartSelect() {
		this._editTitle = "Selektiere Icon";
		this._selectIcon = true;
		this._editwindow.refresh();
	}

	private OnIconSelect(newiconname) {
		this._selectIcon = false;
		this.setNewIcon(newiconname);
		this._editJobOfferDetail.de_detail.iconclass = newiconname;
		this._editJobOfferDetail.en_detail.iconclass = newiconname;
	}

	private OnFilterKeyUp() {
		$('#filterPreview').html(null);

		this._filterIconTimer = Triggers.debounce(this._filterIconTimer, () => {
			this.Log_Info("debounce");
			if (!StringHelper.IsNullOrEmpty(this.filterIconText)) {
				//fuzzy search or approximatly searching
				const fuse = new Fuse(this._solidIconsArr, { threshold: 0.4 });
				var result = fuse.search(this.filterIconText);

				this.filterIconEmpty = result.length == 0;

				if (!this.filterIconEmpty) {
					var iconsString = "";
					for (var i = 0; i < result.length; i++) {
						iconsString = iconsString +
							"<div class='previewFilterIcon' title='" + this._solidIconsArr[result[i].refIndex] +"' data-class='" + this._solidIconsArr[result[i].refIndex] + "'>" +
							"<i class='fa-2x " + this._solidIconsArr[result[i].refIndex] + "' ></i>" +
							"</div>"
					}
					$('#filterPreview').html(iconsString);

					var that = this;
					$(".previewFilterIcon").click(function (ev) {
						that.OnIconSelect(ev.delegateTarget.dataset["class"]);
					});
				}
			} else {
				this.filterIconEmpty = false;
			}
		});
	}

	//edit detail function
	private OnEdit(type, index) {
		this._editJobOfferDetail.detailType = type;
		this._editIndex = index;
		switch (type) {
			case 0:
				for (var i = 0; i < this._jobOfferInput.TextItemList.length; i++) {
					var arr = this._jobOfferInput.TextItemList[i].knowledges[index];
					this._editJobOfferDetail[this._jobOfferInput.TextItemList[i].language + "_detail"] = new JobOfferDetail(arr.iconclass, arr.title, arr.subtitle);
				}
				this.setNewIcon(this._jobOfferInput.TextItemList[0].knowledges[index].iconclass);
				break;
			case 1:
				for (var i = 0; i < this._jobOfferInput.TextItemList.length; i++) {
					var arr = this._jobOfferInput.TextItemList[i].tasks[index];
					this._editJobOfferDetail[this._jobOfferInput.TextItemList[i].language + "_detail"] = new JobOfferDetail(arr.iconclass, arr.title, arr.subtitle);
				}
				this.setNewIcon(this._jobOfferInput.TextItemList[0].tasks[index].iconclass);
				break;
			case 2:
				for (var i = 0; i < this._jobOfferInput.TextItemList.length; i++) {
					var arr = this._jobOfferInput.TextItemList[i].offers[index];
					this._editJobOfferDetail[this._jobOfferInput.TextItemList[i].language + "_detail"] = new JobOfferDetail(arr.iconclass, arr.title, arr.subtitle);
				}
				this.setNewIcon(this._jobOfferInput.TextItemList[0].offers[index].iconclass);
				break;
			default:
				break;
		}
		this.OnOpenJobOfferEdit(type);
	}

	//delete details function
	private OnKnowledgeDelete(index) {
		for (var i = 0; i < this._jobOfferInput.TextItemList.length; i++) {
			this._jobOfferInput.TextItemList[i].knowledges.splice(index, 1);
		}
	}

	private OnTaskDelete(index) {
		for (var i = 0; i < this._jobOfferInput.TextItemList.length; i++) {
			this._jobOfferInput.TextItemList[i].tasks.splice(index, 1);
		}
	}

	private OnOfferDelete(index) {
		for (var i = 0; i < this._jobOfferInput.TextItemList.length; i++) {
			this._jobOfferInput.TextItemList[i].offers.splice(index, 1);
		}
	}

	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  

	redirect(newTab, newparams) {
		window.scrollTo(0, 0);
		this._router.navigateToRoute(newTab, newparams);
	}

	setNewIcon(iconname) {
		var icon = "<i class='fa-2x " + iconname + "'></i>"
		this._iconname = iconname;
		document.getElementById("previewIcon").innerHTML = icon;
	}

	editDetail(ev: Event) {
		//prevent cause of submit form trigger
		ev.preventDefault();

		//same detailType (overwrite the old one)
		if (this._editType == this._editJobOfferDetail.detailType) {
			for (var i = 0; i < this._jobOfferInput.TextItemList.length; i++) {
				var lang = this._jobOfferInput.TextItemList[i].language;

				switch (this._editJobOfferDetail.detailType) {
					case 0:
						this._jobOfferInput.TextItemList[i].knowledges.splice(this._editIndex, 1, this._editJobOfferDetail[lang + "_detail"]);
						break;
					case 1:
						this._jobOfferInput.TextItemList[i].tasks.splice(this._editIndex, 1, this._editJobOfferDetail[lang + "_detail"]);
						break;
					case 2:
						this._jobOfferInput.TextItemList[i].offers.splice(this._editIndex, 1, this._editJobOfferDetail[lang + "_detail"]);
						break;
					default:
						break;
				}
			}
			this._editwindow.close();
		}
		//detailType has changed (delete old one and push the new one)
		else {
			switch (this._editType) {
				case 0:
					this.OnKnowledgeDelete(this._editIndex);
					break;
				case 1:
					this.OnTaskDelete(this._editIndex);
					break;
				case 2:
					this.OnOfferDelete(this._editIndex);
					break;
				default:
					break;
			}
			this.add();
			this._editwindow.close();
		}
	}

	addDetail(ev: Event) {
		//prevent cause of submit form trigger
		ev.preventDefault();

		this.add();
		this._editwindow.close();
	}

	add() {
		for (var i = 0; i < this._jobOfferInput.TextItemList.length; i++) {
			var lang = this._jobOfferInput.TextItemList[i].language;

			switch (this._editJobOfferDetail.detailType) {
				case 0:
					this._jobOfferInput.TextItemList[i].knowledges.push(this._editJobOfferDetail[lang + "_detail"]);
					break;
				case 1:
					this._jobOfferInput.TextItemList[i].tasks.push(this._editJobOfferDetail[lang + "_detail"]);
					break;
				case 2:
					this._jobOfferInput.TextItemList[i].offers.push(this._editJobOfferDetail[lang + "_detail"]);
					break;
				default:
					break;
			}
		}
	}

	create() {
		var result = this.checkDataAndGenerateFormData();
		if (result != null) {
			this._jobOfferService.Create(result).then((result) => {
				if (result) this.redirect("dashboard", { "pointer": "joboffer" });
			});
		}
	}

	update() {
		var result = this.checkDataAndGenerateFormData();
		if (result != null) {
			this._jobOfferService.Update(this._indexOfEditedObj, result).then((result) => {
				if (result) this.redirect("dashboard", { "pointer": "joboffer" });
			});
		}
	}

	checkDataAndGenerateFormData() {
		//check Knowledges
		if (this._jobOfferInput.TextItemList[0].knowledges.length == 0) {
			this.Alert_Error(this._i18n.tr("joboffer.edit.entryrequiredfordetail", { type: this._i18n.tr("career.0_title") }));
			return null;
		}
		//check Tasks
		else if (this._jobOfferInput.TextItemList[0].tasks.length == 0) {
			this.Alert_Error(this._i18n.tr("joboffer.edit.entryrequiredfordetail", { type: this._i18n.tr("career.1_title") }));
			return null;
		}
		//check Offers
		else if (this._jobOfferInput.TextItemList[0].offers.length == 0) {
			this.Alert_Error(this._i18n.tr("joboffer.edit.entryrequiredfordetail", { type: this._i18n.tr("career.2_title") }));
			return null;
		}

		//store new JobOffer
		var newFormData = new FormData();
		for (var i = 0; i < this._selectedClientPictureList.length; i++) {
			newFormData.append("rawclientfiles", this._selectedClientPictureList[i].data);
		}
		newFormData.append("clientfilesJSON", JSON.stringify(this._selectedClientPictureList));
		newFormData.append("serverfilesJSON", JSON.stringify(this._selectedServerPictureList));
		newFormData.append("input", JSON.stringify(this._jobOfferInput));
		return newFormData;
	}
    //#endregion Impl
}


