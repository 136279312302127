import { autoinject, singleton } from "aurelia-framework";

import { BaseClass } from '../../core/base-class';
import { IJobOffer } from "../../interfaces/IJobOffer";
import { LoginContext } from '../../models/LoginContext';
import { CommunicationService } from "../comm-service";
import { LocalesService } from "../locales-service";

@singleton()
@autoinject()
export class JobOfferService extends BaseClass {
    //#region Variables

    //#region -------------------------------------       Public        -------------------------------------

    //#endregion -------------------------------------       Public        -------------------------------------

    //#region -------------------------------------       Protected     -------------------------------------
    //#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	// Info @Thomas : Use IoC from Aurelia in constructor with @autoinject and with parameters in constructor. No need for private variable in this case....!
	//private _httpClient: HttpClient;
    //#endregion -------------------------------------       Private       -------------------------------------

    //#endregion Variables

    //#region CTOR / INITIALIZE
	constructor(private _commuService: CommunicationService,
				private _loginContext: LoginContext,
				private _localesService: LocalesService) {
		super('JobOffer-Service');

		//this._httpClient = new HttpClient();
    }
    //#endregion CTOR / INITIALIZE

    //#region Events   

    //#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
    }

	detached() {
		super.detached();
    }

    //#endregion -------------------------------------       Framework-Events       -------------------------------------

    //#region -------------------------------------       Custom-Events       -------------------------------------

    //#endregion -------------------------------------       Custom-Events       -------------------------------------

    //#endregion Events

	//#region Impl  
	public async GetAll(): Promise<IJobOffer[]> {
		let response = await this._commuService.getResponse('api/joboffer/get');

		switch (response.status) {
			case 200:
				let arr = JSON.parse(JSON.stringify(await response.json())) as IJobOffer[];
				return arr;
			default:
				this.Log_Error("[Get] failed: [status - " + response.status + "]" + response.statusText);
				break;
		}

		return null;
	}

	public async Get(id: number): Promise<IJobOffer> {
		let response = await this._commuService.getResponse('api/joboffer/get?id='+ id);

		switch (response.status) {
			case 200:
				var arr = JSON.parse(JSON.stringify(await response.json())) as IJobOffer[];
				return arr[0];
			default:
				this.Log_Error("[Get] failed: [status - "+ response.status + "]" + response.statusText);
				break;
		}

		return null;
	}

	public async Create(input: FormData) {
		this.Log_UserInput("create_JobOffer");

		let response = await this._commuService.postFileWithResponse('api/joboffer/create', input, this._loginContext.token);

		switch (response.status) {
			case 200:
				//success
				await this._i18n.i18next.reloadResources();
				this.Alert_Success("Create JobOffer Input successfully");
				this.Log_UserInput("[create] Create JobOffer Input successfully!");
				return true;
			case 422:
				//validation error
				var keyValue = this.ValidationFirstKeyValue(await response.json());
				this.Alert_Error(keyValue.value);
				this.Log_Error("[create] failed: [ValidationError status - " + response.status + "] " + keyValue.value);
				break;
			default:
				//error
				this.Log_Error("[create] failed: [status - " + response.status + "]");
				break;
		}
		return false;
	}

	public async Update(id: number, input: FormData) {
		this.Log_UserInput("update_JobOffer");

		let response = await this._commuService.postFileWithResponse('api/joboffer/update/'+ id, input, this._loginContext.token);
		this.Log_Debug("Step1");
		switch (response.status) {
			case 200:
				//success
				await this._i18n.i18next.reloadResources();
				this.Alert_Success("Update JobOffer Input successfully");
				this.Log_UserInput("[update] Update JobOffer Input successfully!");
				return true;
			case 422:
				//validation error
				var keyValue = this.ValidationFirstKeyValue(await response.json());
				this.Alert_Error(keyValue.value);
				this.Log_Error("[update] failed: [ValidationError status - " + response.status + "] " + keyValue.value);
				break;
			default:
				//error
				this.Log_Error("[update] failed: [status - " + response.status + "]");
				break;
		}
		return false;
	}

	public async Delete(id: number): Promise<number> {
		let response = await this._commuService.deleteWithCode('api/joboffer/delete', id, this._loginContext.token);

		switch (response) {
			case 200:
				let response = await this._localesService.RemoveKey("career.joboffers." + id);
				break;
			default:
				this.Log_Error("[Delete] failed: [status - " + response + "]");
				break;
		}

		return response;
	}

	public async SwitchVisibility(id: number): Promise<number> {
		this.Log_UserInput("SwitchVisibility_JobOffer");
		let response = await this._commuService.postWithResponse('api/joboffer/switchvisibility/', id, this._loginContext.token);
		console.log(response);
		switch (response.status) {
			case 200:
				//success
				await this._i18n.i18next.reloadResources();
				this.Alert_Success("Toggle JobOffer Visibility successfully");
				this.Log_UserInput("[switchvisibility] Update JobOffer Input successfully!");
				break;
			case 422:
				//validation error
				var keyValue = this.ValidationFirstKeyValue(await response.json());
				this.Alert_Error(keyValue.value);
				this.Log_Error("[switchvisibility] failed: [ValidationError status - " + response.status + "] " + keyValue.value);
				break;
			default:
				//error
				this.Log_Error("[switchvisibility] failed: [status - " + response.status + "]");
				break;
		}
		return response.status;
	}

	public async GetPictureList(): Promise<string[]> {
		let response = await this._commuService.getResponse('api/joboffer/getpictures', this._loginContext.token);

		switch (response.status) {
			case 200:
				let data = JSON.parse(JSON.stringify(await response.json())) as string[];
				return data;
			default:
				this.Log_Error("[getpictures] failed: [status - " + response.status + "]");
				break;
		}
		return null;
	}

    //#endregion Impl
}