export class StringHelper {

	/*
	* src:			<string1>.<string2>.<stringx>
	* delimiter:	string with one or many chars
	* begin:		0 ... first (<string1>)
	* untilEnd:		false ... if begin is 1 than <string2>
	*				true  ... if begin is 1 than <string2>.<stringx>
	*/
	public static getStringByDelimiter(src: string, delimiter: string, begin: number, untilEnd: boolean = false) {
		let result: string = src;

		if (result != undefined && result.indexOf(delimiter) != -1) {
			let arrayOfStrings = result.split(delimiter);
			if (!untilEnd) {
				result = arrayOfStrings[begin];
			} else {
				result = "";
				for (var i = begin; i < arrayOfStrings.length; i++) {
					result += arrayOfStrings[i];
					if ((i + 1) < arrayOfStrings.length) {
						result += delimiter;
					}
				}
			}
		}
		return result;
	}

	public static IsNullOrEmpty(val: string) {
		return val == null || val == "";
	}
}

