import { inject, bindable } from 'aurelia-framework';
import { BaseClass } from '../../base-class';
import ResizeObserver from 'resize-observer-polyfill';

import './core-image.scss';

@inject(Element)
export class CoreImageCustomElement extends BaseClass {
	//#region Variables

	//#region -------------------------------------       Bindables        -------------------------------------
	@bindable path: string;
	@bindable alt: string;
	@bindable format: string;
	@bindable width: string;
	@bindable responsivewidth: string;
	@bindable link: string = null;
	@bindable target: string = "_blank";
	@bindable maskcolor: string = null;
    //#endregion -------------------------------------       Bindables        -------------------------------------

	//#region -------------------------------------       Public        -------------------------------------
	xScale: number;
	yScale: number;
	calcWidth: number;
	calcHeight: number;

	responsive_arr: Map<number, string> = new Map();
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	_elem: Element;
	_resizeObserver: ResizeObserver;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(elem) {
		super("Component-Core-Image");
		this._elem = elem;
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	attached() {
		super.attached();

		var imgElem = this._elem.getElementsByClassName("core-img")[0] as HTMLElement;
		imgElem.style.width = this.width;

		if (this.responsivewidth != null) {
			var arr = this.responsivewidth.split(";");
			for (var i = 0; i < arr.length; i++) {
				var key_val = arr[i].split(":");
				this.responsive_arr.set(Number.parseInt(key_val[0]), key_val[1]);
			}

			window.addEventListener('resize', () => { this.resizeWindow() }, true);

			this.resizeWindow();
		}

		if (this.format != null) {
			//get format
			var arr = this.format.split(":");
			this.xScale = Number.parseInt(arr[0]);
			this.yScale = Number.parseInt(arr[1]);
			//calc image height
			this.recalcImage();
			//set an Resize Observer
			
			this._resizeObserver = new ResizeObserver(entries => {
				this.recalcImage();
			});
			this._resizeObserver.observe(imgElem);
		}
	}

	detached() {
		super.detached();
		if (this._resizeObserver != null) {
			this._resizeObserver.disconnect();
		}
	}
	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	resizeWindow() {
		var respwidth = this.width;

		this.responsive_arr.forEach((val, key, map) => {
			if (key >= window.innerWidth) {
				respwidth = val;
			}
		})

		var imgElem = this._elem.getElementsByClassName("core-img")[0] as HTMLElement;
		imgElem.style.width = respwidth;
	}
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  

	private recalcImage() {
		var imgElem = this._elem.getElementsByClassName("core-img")[0] as HTMLElement;

		var px_base = imgElem.clientWidth / this.xScale;
		imgElem.style.height = (px_base * this.yScale).toString() + "px";

		this.calcWidth = imgElem.clientWidth;
		this.calcHeight = px_base * this.yScale;
	}
	//#endregion Impl
}