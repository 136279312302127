import { autoinject, singleton, LogManager } from "aurelia-framework";
import { HttpClient } from 'aurelia-fetch-client';
import { LoginContext } from '../models/LoginContext';


interface IHeaderConfig {
	'Authorization': string,
	'Content-type': string
}
export interface ILog {
	debug(val): void;
	info(val): void;
	error(val): void;
}

enum CommunicationResultTypes {
	Success,
	Json,
	Response,
	Code
}

@singleton()
@autoinject
export class CommunicationService {
	private readonly log: ILog = LogManager.getLogger('Communication');

	constructor(protected _loginContext: LoginContext, protected _httpClient: HttpClient) {
	}

	public async getStatusOnly(url: string, token: string = null): Promise<number> {
		return (await this._httpClient.fetch(url, { headers: this.getJsonHeader(token) })).status;
	}

	public async get<T>(url: string, token: string = null): Promise<T> {
		let result = await this._httpClient.fetch(url, { headers: this.getJsonHeader(token) });
		if (result.ok) {
			const res = await result.json();
			return res;
		}
		else {
			return null;
		}
	}
	public async getResponse(url: string, token: string = null): Promise<Response> {
		let result = await this._httpClient.fetch(url, { headers: this.getJsonHeader(token) });
		return result;
	}

	public async delete(url: string, token: string = null): Promise<number> {
		let res = await this._httpClient.fetch(url, {
			method: 'DELETE',
			headers: this.getJsonHeader(token)
		});
		return res.status;
	}
	public async deleteWithResult<T>(url: string, token: string = null): Promise<T> {
		let res = await this._httpClient.fetch(url, {
			method: 'DELETE',
			headers: this.getJsonHeader(token)
		});
		if (res.ok)
			return await res.json();
		return null;
	}
	public async deleteWithCode(url: string, data: Object | string, token: string = null): Promise<number> {
		return await this.send<number>(url, 'DELETE', data, token, CommunicationResultTypes.Code);
	}

	public async patch(url: string, data: object | string, token: string = null): Promise<boolean> {
		return await this.send<boolean>(url, 'PATCH', data, token, CommunicationResultTypes.Success);
	}
	public async put(url: string, data: Object | string, token: string = null): Promise<boolean> {
		return await this.send<boolean>(url, 'PUT', data, token, CommunicationResultTypes.Success);
	}
	public async putWithCode(url: string, data: Object | string, token: string = null): Promise<number> {
		return await this.send<number>(url, 'PUT', data, token, CommunicationResultTypes.Code);
	}
	public async putWithResponse(url: string, data: Object | string, token: string = null): Promise<Response> {
		return await this.send<Response>(url, 'PUT', data, token, CommunicationResultTypes.Response);
	}
	public async putWithResult<T>(url: string, data: Object | string, token: string = null): Promise<T> {
		let res = await this.putWithResponse(url, data, token);
		if (res.ok)
			return await res.json();
		return null;
	}
	public async post(url: string, data: Object | string, token: string = null, isJson: boolean = true): Promise<boolean> {
		return await this.doPost<boolean>(url, data, isJson, token, CommunicationResultTypes.Success);
	}

	public async postWithResult<T>(url: string, data: Object | string, token: string = null, isJson: boolean = true): Promise<T> {
		return await this.doPost<T>(url, data, isJson, token, CommunicationResultTypes.Json);
	}
	public async postWithCode(url: string, data: Object | string, token: string = null, isJson: boolean = true): Promise<number> {
		return await this.doPost<number>(url, data, isJson, token, CommunicationResultTypes.Code);
	}
	public async postWithResponse(url: string, data: Object | string, token: string = null, isJson: boolean = true): Promise<Response> {
		return await this.doPost<Response>(url, data, isJson, token, CommunicationResultTypes.Response);
	}

	private async doPost<T>(url: string, data: Object | string, isJson: boolean = true, token: string = null, resultType: CommunicationResultTypes = CommunicationResultTypes.Success): Promise<T> {
		if (isJson)
			return <T>(await this.send<T>(url, 'POST', data, token, resultType));
		else {
			let res = await this._httpClient.fetch(url, {
				method: 'POST',
				headers: this.getFormHeader(token),
				body: data == null ? null : this.encodeJsonToXFormUrlEnc(data)
			});
			let returnVal = this.handleResult<T>(res, resultType);
			return returnVal;
		}
	}

	public async postFileWithResponse(url: string, data: FormData, token: string): Promise<Response> {
		let client = new HttpClient();
		client.configure(config => {
			config
				.withDefaults({
					headers: {
						'Accept': 'application/json'
					}
				})
		});
		let res = await client.fetch(url, {
			method: 'POST',
			headers: { 'Authorization': "Bearer " + token },
			body: data
		});
		return res;
	}

	private async handleResult<T>(res: Response, resultType: CommunicationResultTypes): Promise<T> {
		// To calm down the compiler do several castings with the result if required.
		switch (resultType) {
			case CommunicationResultTypes.Success:
				return <T>(<any>res.ok);
			case CommunicationResultTypes.Json:
				if (res.ok)
					try {
						return await res.json();
					}
					catch (e) {
						this.log.error(e);
						return null;
					}
				else
					return null;
			case CommunicationResultTypes.Response:
				return <T>(<any>res);
			case CommunicationResultTypes.Code:
				return <T>(<any>res.status);
			default:
				this.log.error('Invalid result type: ' + resultType);
				return null;
		}
	}

	private async send<T>(url: string, method: string, data: Object | string, token: string = null, resultType: CommunicationResultTypes = CommunicationResultTypes.Success): Promise<T> {
		if (method !== 'PUT' && method !== 'POST' && method !== 'PATCH' && method !== 'DELETE')
			throw Error('Invalid method: ' + method);

		// Ensure there is a string - stringify or let it be.
		let dataToSend: string;
		if (data instanceof Object)
			dataToSend = JSON.stringify(data);
		else
			dataToSend = data;

		let result = await this._httpClient.fetch(url, {
			method: method,
			headers: this.getJsonHeader(token),
			body: dataToSend
		});

		let resultVal = this.handleResult<T>(result, resultType);
		return resultVal;
	}

	private getFormHeader(token: string = null): IHeaderConfig {
		return this.getHeader('application/x-www-form-urlencoded', token);
	}
	private getFormFileHeader(token: string = null): IHeaderConfig {
		return this.getHeader('multipart/form-data', token);
	}

	private getJsonHeader(token: string = null): IHeaderConfig {
		return this.getHeader('application/json', token);
	}
	private getHeader(contentType: string, token: string = null): IHeaderConfig {
		let headers: any;
		if (token != null && token != '') {
			headers = {
				'Authorization': "Bearer " + token,
				'Content-type': contentType,
			};
		}
		else {
			headers = { 'Content-type': contentType };
		}
		return headers;
	}

	private encodeJsonToXFormUrlEnc(data: object | string): string {
		if (data == null)
			return null;
		if (typeof data === 'string')
			return <string>data;
		if (typeof data !== "object") {
			this.log.error('data is not an object. Can not encode. The type is: ' + typeof data);
			return null;
		}

		let urljson = "";
		let keys = Object.keys(data);
		for (var i = 0; i < keys.length; i++) {
			urljson += encodeURIComponent(keys[i]) + "=" + encodeURIComponent(data[keys[i]]);
			if (i < (keys.length - 1))
				urljson += "&";
		}
		return urljson;
	}
}