import { autoinject, DOM } from "aurelia-framework";

import { BaseClass } from '../../core/base-class';
import { LoginContext } from '../../models/LoginContext';

import './contact.scss';

import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';

@autoinject()
export class Contact extends BaseClass {
	//#region Variables
	static viewmodel: Contact;
	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	private map: any;
	private arec_coords: any;
	private default_coords: any;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext) {
		super('View-Contact');
		Contact.viewmodel = this;
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");
	}
		

	attached() {
		super.attached();

		
		this.default_coords = L.latLng(48.124133, 14.934099);
		this.arec_coords = L.latLng(48.114133, 14.934099);
		var zoomfactor = window.innerWidth < 1150 ? 11 : 12;
		this.map = L.map('mapid', { zoomControl: false }).setView(this.default_coords, zoomfactor);
		let urlTemplate = 'https://b.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png';
		this.map.addLayer(L.tileLayer(urlTemplate, { minZoom: 4, attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' }));

		//Marker Definitions 
		const arecIcon = new L.DivIcon({
			className: 'leaflet-icon',
			html: '<div class="leaflet-icon-div"><svg class="leaflet-icon-image" viewBox="0 0 64.17733 18.720018"><defs><style id="style10">.cls - 1 {fill: #214a90;}</style></defs><g transform = "translate(-10.640288,-10.620643)" ><g transform="matrix(0.26458333,0,0,0.26458333,10.640288,1.490229)" ><path class="cls-1" d = "m 213.5,93.35 a 23.45,23.45 0 0 1 0,-46.89 22.18,22.18 0 0 1 18.66,9.83 l 10.4,-7.78 a 36.51,36.51 0 0 0 -29.06,-14 c -20.38,0 -36.57,15.49 -36.57,35.38 0,19.89 16.19,35.37 36.57,35.37 a 36.48,36.48 0 0 0 29.06,-14 L 232.37,84 a 23.28,23.28 0 0 1 -18.87,9.35 z" /><path class="cls-1" d = "M 122.37,55.46 C 122.37,43.69 115,35.89 99.91,35.89 H 66.15 V 63 h -13 l 5.07,11.93 h 7.9 v 28.76 L 37.41,35.88 H 20.9 l -4.61,11.26 h 8.54 L 0,103.93 h 13.45 l 5.2,-12.88 h 28.84 l 5.21,12.88 h 26 v -29 h 12.52 l 17.16,29 h 14.86 L 105.12,74.6 c 12.81,-1.74 17.25,-10.2 17.25,-19.14 z M 23.21,79.8 33.21,55.46 43.05,79.8 Z M 78.73,63 V 47.81 H 101 c 6.52,0 8.8,4.09 8.8,7.65 0,3.56 -2.29,7.54 -8.8,7.54 z" /><path class="cls-1" d = "m 133.16,63 h -7.6 a 16.67,16.67 0 0 1 -10.43,11.93 h 18 v 29 h 43.43 V 92 H 145.74 V 74.92 h 25.52 c -0.16,-1.3 -0.25,-2.63 -0.28,-4 a 41.24,41.24 0 0 1 0.62,-8 H 145.74 V 47.81 h 30.85 V 35.89 h -43.43 z" /></g></g></svg></div>'
		});

		const startWestIcon = new L.DivIcon({
			className: 'leaflet-icon',
			html: '<div class="leaflet-icon-subicon-div"><i class="fas fa-road"></i></div><div class="leaflet-icon-div"><p class="leaflet-icon-p">Amstetten West</p></div>'
		});

		const startOstIcon = new L.DivIcon({
			className: 'leaflet-icon',
			html: '<div class="leaflet-icon-subicon-div"><i class="fas fa-road"></i></div><div class="leaflet-icon-div"><p class="leaflet-icon-p">Amstetten Ost</p></div>'
		});

		const noIcon = new L.DivIcon({
			className: 'd-none',
		});

		//Route WestAutobahn nach AREC
		L.Routing.control({
			waypoints: [
				L.latLng(48.133403, 14.838644),
				this.arec_coords
			],
			lineOptions: {
				addWaypoints: false,
				styles: [
					{ color: 'white', opacity: 0.9, weight: 9 },
					{ color: '#004C8A', opacity: 1, weight: 3 }
				]
			},
			createMarker: (i, start, n) => { return this.createMarker(i, start, n, startWestIcon, arecIcon); }
		}).addTo(this.map);

		//Route OstAutobahn nach AREC
		L.Routing.control({
			waypoints: [
				L.latLng(48.128773, 15.010707),
				this.arec_coords
			],
			lineOptions: {
				addWaypoints: false,
				styles: [
					{ color: 'white', opacity: 0.9, weight: 9 },
					{ color: '#98B7D4', opacity: 1, weight: 3 }
				]
			},
			createMarker: (i, start, n) => { return this.createMarker(i, start, n, startOstIcon, noIcon); }
		}).addTo(this.map);

		//adding Events
		document.getElementById("expandMap").addEventListener("click", this.expandMap);
		document.addEventListener('fullscreenchange', this.fullscreenchanged);
	}

	detached() {
		document.removeEventListener('fullscreenchange', this.fullscreenchanged);
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------

	createMarker(i, start, n, startIcon, endIcon) {
		var marker_icon = null
		if (i == 0) {
			// This is the first marker, indicating start
			marker_icon = startIcon
		} else if (i == n - 1) {
			//This is the last marker indicating destination
			marker_icon = endIcon
		}
		var marker = L.marker(start.latLng, {
			draggable: false,
			bounceOnAdd: false,
			bounceOnAddOptions: {
				duration: 1000,
				height: 800
			},
			icon: marker_icon
		})
		return marker;
	}

	expandMap() {
		var map = document.getElementById("mapid");
		if (document.fullscreen) {
			document.exitFullscreen();
		} else {
			map.requestFullscreen();
		}
	}

	fullscreenchanged() {
		var that = Contact.viewmodel;
		var elements = document.getElementsByClassName("leaflet-routing-container");
		if (document.fullscreen) {
			for (var i = 0; i < elements.length; i++) {
				elements[i].classList.add("d-block");
				elements[i].classList.remove("d-none");
			}
			var zoomIn = 13;
			if (window.innerWidth <= 991) {
				zoomIn = 11;
			}
			that.map.setView(that.default_coords, zoomIn);
		} else {
			for (var i = 0; i < elements.length; i++) {
				elements[i].classList.remove("d-block");
				elements[i].classList.add("d-none");
			}
			var zoomOut = 11;
			that.map.setView(that.default_coords, zoomOut);
		}
	}
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  

    //#endregion Impl
}