import { JobOfferTextInput } from "./JobOfferTextInput";


export class JobOfferInput {
	TextItemList: JobOfferTextInput[];
	imagePath: any;
	isVisible: boolean;

	constructor(textInputList, imagePath, isVisible) {
		this.TextItemList = textInputList;
		this.imagePath = imagePath;
		this.isVisible = isVisible;
	}
}