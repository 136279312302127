import { JobOfferDetail } from "./JobOfferDetail";

export class JobOfferTextInput {
	title: string;
	language: string;
	knowledges: JobOfferDetail[] = [];
	tasks: JobOfferDetail[] = [];
	offers: JobOfferDetail[] = [];

	constructor(language) {
		this.language = language;
	}
}