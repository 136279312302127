import { inject, bindable } from 'aurelia-framework';
import { BaseClass } from '../../core/base-class';
import { gsap } from 'gsap';

import './arec-productsvg.scss';

@inject(Element)
export class ArecProductsvgCustomElement extends BaseClass {
	//#region Variables
	//#region -------------------------------------       Bindables        -------------------------------------

	//#endregion -------------------------------------       Bindables        -------------------------------------

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	_tl: gsap.core.Timeline;
	_elem: HTMLElement;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(elem) {
		super('Layout-ArecProductsvg');
		this._elem = elem;
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
	}

	detached() {
		super.detached();
		
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	onmouseenter() {
		this.animateSVG();
	}

	onmouseleave() {
		this._tl.restart();
		this._tl.clear();
	}
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	animateSVG() {
		this._tl = gsap.timeline();
		this._tl.to("#product-line-1-1", { fill: "#d5d5d5", scaleX: 0, duration: 0.1 })
				.to("#product-line-2-1", { fill: "#d5d5d5", scaleX: 0, duration: 0.1 })
				.to("#product-line-1-1", { fill: "#004C8A", scaleX: 1, duration: 0.15 })
				.to("#product-line-2-1", { fill: "#004C8A", scaleX: 1, duration: 0.15 })
				.to("#product-line-1-2", { fill: "#d5d5d5", scaleX: 0, duration: 0.1 })
				.to("#product-line-2-2", { fill: "#d5d5d5", scaleX: 0, duration: 0.1 })
				.to("#product-line-1-2", { fill: "#004C8A", scaleX: 1, duration: 0.15 })
				.to("#product-line-2-2", { fill: "#004C8A", scaleX: 1, duration: 0.15 })
				.to("#product-line-3-1", { fill: "#d5d5d5", scaleX: 0, duration: 0.1 })
				.to("#product-line-2-3", { fill: "#d5d5d5", scaleX: 0, duration: 0.1 })
				.to("#product-line-3-1", { fill: "#004C8A", scaleX: 1, duration: 0.15 })
				.to("#product-line-2-3", { fill: "#004C8A", scaleX: 1, duration: 0.15 })
				.to(".product-box", { stroke: "#004C8A", delay: 0.25, duration: 1 });
	}
	//#endregion Impl
}

