import { TaskQueue, autoinject, DOM } from "aurelia-framework";
import { ready, newInstance, BrowserJsPlumbInstance } from "@jsplumb/browser-ui";

import { BaseClass } from '../../../core/base-class';
import { ICompetence } from "../../../interfaces/ICompetence";
import { IProduct } from "../../../interfaces/IProduct";
import { BrancheInput } from "../../../models/BrancheInput";
import { BrancheTextInput } from "../../../models/BrancheTextInput";
import { JSPlumbRelation } from "../../../models/JSPlumb_Relation";
import { LoginContext } from '../../../models/LoginContext';
import { BrancheService } from "../../../services/project/branche-service";
import { CompetenceService } from "../../../services/project/competence-service";
import { ProductService } from "../../../services/project/product-service";

import './edit.scss';



@autoinject()
export class Edit extends BaseClass {
	//#region Variables
	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	private _indexOfEditedObj = null;
	private _brancheTextInput: BrancheTextInput[];
	private _brancheInput: BrancheInput;
	private _products: IProduct[];
	private _competences: ICompetence[];
	private _relProductBranche: JSPlumbRelation[] = [];
	private _relCompetenceBranche: JSPlumbRelation[] = [];
	private _isLoading: boolean = true;
	private _pictureList: string[];
	private _selectedServerPictureList: any[] = [];
	private _selectedClientPictureList: any[] = [];
	private _videoList: string[];
	private _selectedServerVideoList: any[] = [];
	private _selectedClientVideoList: any[] = [];
	private _jsplumb: BrowserJsPlumbInstance;
	
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext,
				private _taskQueue: TaskQueue,
				private _brancheService: BrancheService,
				private _competenceService: CompetenceService,
				private _productService: ProductService) {
		super('View-Edit_Branche');


		this._brancheService.GetPictureList().then((response) => {
			this._pictureList = response;
		});
		this._brancheService.GetVideoList().then((response) => {
			this._videoList = response;
		});
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");

		var that = this;
		window.addEventListener("resize", function (e) {
			that._jsplumb.repaintEverything();
		});
	}

	activate(params) {
		this._indexOfEditedObj = params.id;

		this._brancheTextInput = [];
		this._brancheTextInput.push(new BrancheTextInput("de"));
		this._brancheTextInput.push(new BrancheTextInput("en"));
		this._brancheInput = new BrancheInput(this._brancheTextInput);

		//if '_indexOfJobOffer' has an value, the user will edit an existing JobOffer
		if (this._indexOfEditedObj != null) {
			this._isLoading = true;
			this._brancheService.Get(this._indexOfEditedObj).then((resp) => {
				var imagesArr = JSON.parse(resp.JsonImagePaths);
				//adding selected image to imagepicker and select the right image
				for (var i = 0; i < imagesArr.length; i++) {
					this._brancheInput.ImagePaths.push({
						index: i + 1,
						type: "server",
						data: imagesArr[i].substring(imagesArr[i].lastIndexOf("/") + 1)
					});
				}
				this._selectedServerPictureList = this._brancheInput.ImagePaths;
				this._brancheInput.VideoPath = resp.VideoPath;
				if (resp.VideoPath != null) {
					//adding selected video to imagepicker and select the right video
					this._selectedServerVideoList.push({
						index: 1,
						type: "server",
						data: this._brancheInput.VideoPath.substring(this._brancheInput.VideoPath.lastIndexOf("/") + 1)
					});
				}
				this._brancheInput.OrderNumber = resp.OrderNumber;
				//store current translation language
				var oldLocale = this._i18n.getLocale();
				for (var i = 0; i < this._brancheInput.TextItemList.length; i++) {
					var lang = this._brancheInput.TextItemList[i].language;
					// translation language
					this._i18n.setLocale(lang);
					//set title
					this._brancheInput.TextItemList[i].title = this._i18n.tr("branche.items." + resp.ID + ".title");
					//set subtitle
					this._brancheInput.TextItemList[i].subtitle = this._i18n.tr("branche.items." + resp.ID + ".subtitle");
					//set description
					this._brancheInput.TextItemList[i].description = this._i18n.tr("branche.items." + resp.ID + ".description");
				}
				for (var i = 0; i < resp.Competences.length; i++) {
					this._relCompetenceBranche.push(new JSPlumbRelation(resp.ID, resp.Competences[i].CompetenceID))
				}
				for (var i = 0; i < resp.Products.length; i++) {
					this._relProductBranche.push(new JSPlumbRelation(resp.ID, resp.Products[i].ProductID))
				}

				//set old translation language
				this._i18n.setLocale(oldLocale);
				this.loadRelationshipLists();
			});
		} else {
			this.loadRelationshipLists();
		}
	}

	attached() {
		super.attached();
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------

	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	private async loadRelationshipLists() {
		this._competences = await this._competenceService.GetAll();
		this._products = await this._productService.GetAll();

		this._isLoading = false;
		this.CallAfterUIUpdate(() => {
			this.jsPlumb_Ready();
		}, 500);
	}

	jsPlumb_Ready() {
		//jsplumb ready
		ready(() => {
			//register default styling and settings
			this._jsplumb = newInstance({
				container: document.getElementById("jsPlumbContainer")
			})

			this._jsplumb.registerConnectionTypes({
				"default": {
					paintStyle: { stroke: "#004C8A", strokeWidth: 4 },
					hoverPaintStyle: { stroke: "#98B7D4", strokeWidth: 5 },
					cssClass: "connector-normal"
				}
			});

			var sourceEP: any = { anchor: ["Left", "Right"] };
			var common = {
				paintStyle: { fill: "#004C8A"},
				endpoint: "Rectangle",
				detachable: true,
				connectorPaintStyle: { type: "basic" }
			};
			//define the source and target elements
			this._jsplumb.makeSource(document.getElementById("jsP_source_branche"), sourceEP, common);
			for (var i = 0; i < this._competences.length; i++) {
				this._jsplumb.makeTarget(document.getElementById("jsP_target_competence_" + this._competences[i].ID), { anchor: "Left" }, common);
			}
			for (var i = 0; i < this._products.length; i++) {
				this._jsplumb.makeTarget(document.getElementById("jsP_target_product_" + this._products[i].ID), { anchor: "Right" }, common);
			}
			//init all connections/relationships
			for (var i = 0; i < this._relCompetenceBranche.length; i++) {
				var con = this._jsplumb.connect({
					target: document.getElementById("jsP_target_competence_" + this._relCompetenceBranche[i].target),
					source: document.getElementById("jsP_source_branche")
				});

				this._relCompetenceBranche[i].conID = con.id;
			}

			for (var i = 0; i < this._relProductBranche.length; i++) {
				var con = this._jsplumb.connect({
					target: document.getElementById("jsP_target_product_" + this._relProductBranche[i].target),
					source: document.getElementById("jsP_source_branche")
				});

				this._relProductBranche[i].conID = con.id;
			}
			//bind to connection and detach events to handle the state
			var that = this;
			this._jsplumb.bind("connection", function (info) {
				var split_Target = info.target.id.split('_')
				var targetID = Number.parseInt(split_Target[3]);
				if (split_Target[2] == "competence") {
					that.checkUniqueAndInsertIfUnique(that._relCompetenceBranche, targetID, info.connection, that._jsplumb);
				} else if (split_Target[2] == "product") {
					that.checkUniqueAndInsertIfUnique(that._relProductBranche, targetID, info.connection, that._jsplumb);
				}
			});

			this._jsplumb.bind("beforeDetach", function (connection) {
				//check competence-branche relationship state
				var competenceBranche_index = null;
				for (var i = 0; i < that._relCompetenceBranche.length; i++) {
					if (that._relCompetenceBranche[i].conID == connection.id) {
						competenceBranche_index = i;
						break;
					}
				}
				if (competenceBranche_index != null) {
					that._relCompetenceBranche.splice(competenceBranche_index, 1);
				}
				//check product-branche relationship state
				else {
					var productBranche_index = null;
					for (var i = 0; i < that._relProductBranche.length; i++) {
						if (that._relProductBranche[i].conID == connection.id) {
							productBranche_index = i;
							break;
						}
					}
					if (productBranche_index != null) {
						that._relProductBranche.splice(productBranche_index, 1);
					}
				}
			});
		})
	}

	checkUniqueAndInsertIfUnique(relationList, targetID, connection, jsPlumbInstance) {
		var unique = true;
		for (var i = 0; i < relationList.length; i++) {
			if (relationList[i].target == targetID) {
				unique = false;
				break;
			}
		}
		if (unique) {
			relationList.push(new JSPlumbRelation(this._indexOfEditedObj ? Number.parseInt(this._indexOfEditedObj) : null, targetID, connection.id));
		} else {
			this.removeConnection(jsPlumbInstance, connection);
		}
	}

	removeConnection(instance, connection) {
		instance.destroyConnection(connection);
		for (var i = connection.endpoints.length - 1; i >= 0; i--) {
			instance.destroyEndpoint(connection.endpoints[i]);
		}
	}

	redirect(newTab, newparams) {
		window.scrollTo(0, 0);
		this._router.navigateToRoute(newTab, newparams);
	}

	create() {
		var result = this.checkDataAndGenerateFormData();
		if (result != null) {
			this._brancheService.Create(result).then((result) => {
				if (result) this.redirect("dashboard", { "pointer": "branche" });
			});
		}
	}

	update() {
		var result = this.checkDataAndGenerateFormData();
		if (result != null) {
			this._brancheService.Update(this._indexOfEditedObj, result).then((result) => {
				if (result) this.redirect("dashboard", { "pointer": "branche" });
			});
		}
	}

	checkDataAndGenerateFormData() {
		//store new Product
		var newFormData = new FormData();
		//images
		for (var i = 0; i < this._selectedClientPictureList.length; i++) {
			newFormData.append("rawclientfiles", this._selectedClientPictureList[i].data);
		}
		newFormData.append("clientfilesJSON", JSON.stringify(this._selectedClientPictureList));
		newFormData.append("serverfilesJSON", JSON.stringify(this._selectedServerPictureList));
		//video
		for (var i = 0; i < this._selectedClientVideoList.length; i++) {
			newFormData.append("rawclientvideos", this._selectedClientVideoList[i].data);
		}
		newFormData.append("clientvideosJSON", JSON.stringify(this._selectedClientVideoList));
		newFormData.append("servervideosJSON", JSON.stringify(this._selectedServerVideoList));
		//data
		newFormData.append("input", JSON.stringify(this._brancheInput));
		newFormData.append("relbrancheproductJSON", JSON.stringify(this._relProductBranche));
		newFormData.append("relbranchecompetenceJSON", JSON.stringify(this._relCompetenceBranche));
		return newFormData;
	}
    //#endregion Impl
}


