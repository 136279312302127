import { autoinject } from 'aurelia-dependency-injection';
import { BaseClass } from '../core/base-class';
import * as moment from 'moment';

import './master-header.scss';
import { LoginContext } from '../models/LoginContext';

@autoinject()
export class MasterHeaderCustomElement extends BaseClass {
    //#region Variables
    //#region -------------------------------------       Bindables        -------------------------------------
    //@bindable variable: datatype(boolean,string,...);
    //#endregion -------------------------------------       Bindables        -------------------------------------

    //#region -------------------------------------       Public        -------------------------------------

    //#endregion -------------------------------------       Public        -------------------------------------

    //#region -------------------------------------       Protected     -------------------------------------
    //#endregion -------------------------------------       Protected     -------------------------------------

    //#region -------------------------------------       Private       -------------------------------------
	//Alerting
    //#endregion -------------------------------------       Private       -------------------------------------

    //#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext) {
		super('Layout-MasterHeader');
		this._i18n.setLocale('de');
		moment.locale(this._i18n.getLocale());
    }
    //#endregion CTOR / INITIALIZE

    //#region Events   

    //#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
		let that = this;

		// Listen for return or prev events, here we debounce our `updateNavbar` function
		window.addEventListener('hashchange', function () {
			that.updateNavbar(that._router.currentInstruction.config.name);

			if (!document.getElementById("navbar-toggler").classList.contains("collapsed")) {
				document.getElementById("navbar-toggler").click();
			}
		}, false);
		// set position and tab active
		this.updateNavbar(this._router.currentInstruction.config.name);
    }

	detached() {
		let that = this;
		super.detached();
	}

    //#endregion -------------------------------------       Framework-Events       -------------------------------------

    //#region -------------------------------------       Custom-Events       -------------------------------------
	async changeLang(newLang) {
		//remove active flag 
		document.getElementById(this._i18n.getLocale()).classList.remove("active");
		//set new language
		this._i18n.setLocale(newLang);
		await this._i18n.i18next.reloadResources(this._i18n.getLocale());
		//set active flag
		document.getElementById(this._i18n.getLocale()).classList.add("active"); 
		moment.locale(this._i18n.getLocale());
		this.signal('update-view');
		
		this.Log_UserInput("Change Language to '" + newLang + "'");
	}

	redirect(newTab) {
		this._router.navigateToRoute(newTab);
		this.updateNavbar(newTab);
	}

	updateNavbar(newTab) {
		//set selected tab
		var allActive = document.getElementById("tabList").getElementsByClassName("active");
		for (var i = 0; i < allActive.length; i++) {
			allActive[i].classList.remove("active");
		}

		var navbarItem = document.getElementById(newTab + "Tab");
		if (navbarItem != null) {
			navbarItem.classList.add("active");
		}

		window.scrollTo(0, 0);
	}

	
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

    //#endregion Events

	//#region Impl  
    //#endregion Impl
}