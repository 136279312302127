import { autoinject, singleton } from "aurelia-framework";
import { BaseClass } from "../core/base-class";
import { LoginContext } from "../models/LoginContext";
import { CommunicationService } from "./comm-service";

@singleton()
@autoinject()
export class LocalesService extends BaseClass {
    //#region Variables

    //#region -------------------------------------       Public        -------------------------------------

    //#endregion -------------------------------------       Public        -------------------------------------

    //#region -------------------------------------       Protected     -------------------------------------
    //#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
// Info @Thomas : Use IoC from Aurelia in constructor with @autoinject and with parameters in constructor. No need for private variable in this case....!
	//private _httpClient: HttpClient;
    //#endregion -------------------------------------       Private       -------------------------------------

    //#endregion Variables

    //#region CTOR / INITIALIZE
	constructor(private _commuService: CommunicationService,
				private _loginContext: LoginContext) {
		super('Locales-Service');

		//this._httpClient = new HttpClient();
    }
    //#endregion CTOR / INITIALIZE

    //#region Events   

    //#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
    }

	detached() {
		super.detached();
    }

    //#endregion -------------------------------------       Framework-Events       -------------------------------------

    //#region -------------------------------------       Custom-Events       -------------------------------------

    //#endregion -------------------------------------       Custom-Events       -------------------------------------

    //#endregion Events

	//#region Impl 

	//store a section of json at the translation file
	public async SetJson(lang: string, key: string, json: object): Promise<Response> {
		let response = await this._commuService.postWithResponse('api/locales/setjson', { lang: lang, key: key, json: JSON.stringify(json) }, this._loginContext.token);

		switch (response.status) {
			case 200:
				await this._i18n.i18next.reloadResources();
				break;
			default:
				this.Log_Error("[SetJson] failed: [status - " + response.status + "; text: " + response.statusText + "]");
				break;
		}
		 
		return response; 
	}

	//store a text at the translation file
	public async SetText(lang: string, key: string, value: string): Promise<Response> {
		let response = await this._commuService.postWithResponse('api/locales/settext', { lang: lang, key: key, value: value }, this._loginContext.token);

		switch (response.status) { 
			case 200:
				await this._i18n.i18next.reloadResources();
				break; 
			default:
				this.Log_Error("[SetText] failed: [status - " + response.status + "; text: " + response.statusText + "]");
				break;
		}

		return response;
	}

	public async RemoveKey(key: string): Promise<Response> {
		let response = await this._commuService.postWithResponse('api/locales/removekey', { key: key }, this._loginContext.token);

		switch (response.status) {
			case 200:
				await this._i18n.i18next.reloadResources();
				break;
			default:
				this.Log_Error("[RemoveKey] failed: [status - " + response.status + "; text: " + response.statusText + "]");
				break;
		}

		return response;
	}

    //#endregion Impl
}