import { inject, bindable } from 'aurelia-framework';
import { BaseClass } from '../../core/base-class';

import './arec-card.scss';

@inject(Element)
export class ArecCardCustomElement extends BaseClass {
	@bindable iconclass: string;
	@bindable title: string;
	@bindable subtitle: string;
	@bindable description: string;
	@bindable index: number;
	@bindable slickclass: string;
	@bindable slickchanged: Function;
	@bindable slickimages: string[];
	//#region Variables
	//#region -------------------------------------       Bindables        -------------------------------------
	//@bindable variable: datatype(boolean,string,...);
	//#endregion -------------------------------------       Bindables        -------------------------------------

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(elem) {
		super('Layout-ArecCard');
		
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
		if (this.slickclass != null) {

			$('.' + this.slickclass).slick({
				centerMode: true,
				dots: true,
				slidesToShow: 1,
				focusOnSelect: true,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							arrows: false,
							centerMode: true,
							centerPadding: '40px',
							slidesToShow: 3
						}
					},
					{
						breakpoint: 480,
						settings: {
							arrows: false,
							centerMode: true,
							centerPadding: '40px',
							slidesToShow: 1
						}
					}
				]
			});

			var that = this;
			$('.' + this.slickclass).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
				that.Publish("slick_slide_changed", [that.index, nextSlide]);
			});
		}
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------

	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	
	//#endregion Impl
}

