export class JSPlumbRelation {
	public source: number;
	public target: number;
	public conID: string;

	constructor(source, target, conID = null) {
		this.source = source;
		this.target = target;
		this.conID = conID;
	}
}