import { DOM } from 'aurelia-pal';

export class SubmitFormCustomAttribute {
    //#region Variables
    static inject = [Element];

    //#region -------------------------------------       Bindables        -------------------------------------
    //@bindable variable: datatype(boolean,string,...);
    //#endregion -------------------------------------       Bindables        -------------------------------------

    //#region -------------------------------------       Public        -------------------------------------
    //#endregion -------------------------------------       Public        -------------------------------------

    //#region -------------------------------------       Protected     -------------------------------------
    //#endregion -------------------------------------       Protected     -------------------------------------

    //#region -------------------------------------       Private       -------------------------------------
    private value: Element;
    private formSubmitListener = event => {
        this.dispatchFormSubmit(this.value);
    };
    //#endregion -------------------------------------       Private       -------------------------------------

    //#endregion Variables

    //#region CTOR / INITIALIZE
    constructor(private element: Element) {
    }
    //#endregion CTOR / INITIALIZE

    //#region Events   

    //#region -------------------------------------       Framework-Events       -------------------------------------
    bind() {
        this.element.addEventListener('click', this.formSubmitListener);
    }

    unbind() {
        this.element.removeEventListener('click', this.formSubmitListener);
    }
    //#endregion -------------------------------------       Framework-Events       -------------------------------------

    //#region -------------------------------------       Custom-Events       -------------------------------------
    dispatchFormSubmit(form: Element) {
        const submitEvent = DOM.createCustomEvent('submit', {
            bubbles: true
        });
        submitEvent.preventDefault();
        form.dispatchEvent(submitEvent);
    }
    //#endregion -------------------------------------       Custom-Events       -------------------------------------

    //#endregion Events

    //#region Impl  
    //#endregion Impl
}