import { autoinject, DOM } from "aurelia-framework";

import { BaseClass } from '../../core/base-class';
import { LoginContext } from '../../models/LoginContext';

import './privacy.scss';

@autoinject()
export class Privacy extends BaseClass {
	//#region Variables

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext) {
		super('View-Privacy');
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");
	}
		

	attached() {
		super.attached();
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  

    //#endregion Impl
}