import { inject, bindable } from 'aurelia-framework';
import { BaseClass } from '../../core/base-class';

import './arec-headersvg.scss';

@inject(Element)
export class ArecHeadersvgCustomElement extends BaseClass {
	//#region Variables
	//#region -------------------------------------       Bindables        -------------------------------------

	//#endregion -------------------------------------       Bindables        -------------------------------------

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	private displaynone: string;
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	_elem: HTMLElement;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(elem) {
		super('Layout-ArecHeadersvg');
		this._elem = elem;
		this.displaynone = "d-none"
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
	}

	detached() {
		super.detached();
		
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	
	OnChange() {
		this.Log_UserInput("change svg");
		if (this.displaynone == "d-block") {
			this.displaynone = "d-none";
		} else {
			this.displaynone = "d-block";
		}
	}
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	//#endregion Impl
}

