import { autoinject } from "aurelia-framework";

import { AuthService } from "../../services/auth-service";
import { BaseClass } from '../../core/base-class';

import '@progress/kendo-ui/js/kendo.button.min';

import './login.scss';

@autoinject()
export class Login extends BaseClass {
    //#region Variables
    //#region -------------------------------------       Bindables        -------------------------------------
    //#endregion -------------------------------------       Bindables        -------------------------------------

    //#region -------------------------------------       Public        -------------------------------------
    //#endregion -------------------------------------       Public        -------------------------------------

    //#region -------------------------------------       Protected     -------------------------------------
    //#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	private _username: string;
	private _password: string;
	
    //#endregion -------------------------------------       Private       -------------------------------------

    //#endregion Variables

    //#region CTOR / INITIALIZE
	constructor(private _authService: AuthService) {
        super("View-Login");
        this._username = null;
		this._password = null;
    }
    //#endregion CTOR / INITIALIZE

    //#region Events   

    //#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
    }

	detached() {
		super.detached();
    }

    //#endregion -------------------------------------       Framework-Events       -------------------------------------

    //#region -------------------------------------       Custom-Events       -------------------------------------

	//#region Dropdown Line Events

    //#endregion -------------------------------------       Custom-Events       -------------------------------------

    //#endregion Events

	//#region Impl  
	public async tryLogin(formElement: HTMLFormElement) {
		this._authService.Login(this._username, this._password).then(async response => {
			if (response == 200) {
				this.Alert_Success("Login erfolgreich!");
				this.Log_UserInput("[tryLogin] Login successfully!");
				this._router.navigateToRoute("dashboard", this._urlParams);
			} else if (response == 409) {
				this.Log_UserInput("[tryLogin] Login error Locked");
			} else {
				this.Alert_Error("Login ist fehlgeschlagen! Logindaten sind falsch!");
				this.Log_UserInput("[tryLogin] Login error");
			}
        });
	}
    //#endregion Impl
}

