import { autoinject, DOM } from "aurelia-framework";

import { BaseClass } from '../../core/base-class';
import { IJobOffer } from "../../interfaces/IJobOffer";
import { LoginContext } from '../../models/LoginContext';
import { JobOfferService } from "../../services/project/joboffer-service";

import './career.scss';

@autoinject()
export class Career extends BaseClass {
	//#region Variables

	//#region -------------------------------------       Public        -------------------------------------
	jobssearches = [];
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	_jobOfferList: IJobOffer[] = []
	_isLoading: boolean = true;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext,
				private _jobOfferService: JobOfferService) {
		super('View-Career');
		_jobOfferService.GetAll().then((response: IJobOffer[]) => {
			response.forEach(JobOfferElement => {
				if (JobOfferElement.IsVisible == true) {
					this._jobOfferList.push(JobOfferElement);
				}
			})
		}).finally(() => {
			this._isLoading = false;
		});
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");
	}

	attached() {
		super.attached();
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	openJobsearch(jobOffer: IJobOffer) {
		jobOffer.isopen = !jobOffer.isopen;
	}

	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	redirect(newTab) {
		window.scrollTo(0, 0);
		this._router.navigateToRoute(newTab, this._urlParams);
	}
    //#endregion Impl
}


