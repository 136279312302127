import { TaskQueue, autoinject, bindable, bindingMode } from 'aurelia-framework';
import { BaseClass } from '../../core/base-class';

import './picture-select-window.scss';

@autoinject()
//@inject()
export class PictureSelectWindowCustomElement extends BaseClass {
	actions = ['Close']
	//#region Variables
	//#region -------------------------------------       Bindables        -------------------------------------
	@bindable({ defaultBindingMode: bindingMode.toView }) windowTitle: string;
	@bindable({ defaultBindingMode: bindingMode.toView }) buttonText: string = "pictureSelect";
	@bindable({ defaultBindingMode: bindingMode.toView }) sourceDirectory: string;
	@bindable({ defaultBindingMode: bindingMode.toView }) inputAccept: string;
	@bindable({ defaultBindingMode: bindingMode.toView }) inputLimit: number = null;
	@bindable({ defaultBindingMode: bindingMode.toView }) aspectRatio: string = "16:9";
	@bindable({ defaultBindingMode: bindingMode.toView }) serverObjectNameList: string[];
	@bindable({ defaultBindingMode: bindingMode.twoWay }) selectedServerObjectNameList: any[];
	@bindable({ defaultBindingMode: bindingMode.fromView }) selectedClientObjectList: any[];

	//#endregion -------------------------------------       Bindables        -------------------------------------

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	private _window;
	private _windowActions = ['Close'];
	private _objectPathList: string[] = [];
	private _aspectRatio: number;
	private _currentIndex: number = 0;
	private _isInit: boolean = false;
	selectedFiles;

	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _taskQueue: TaskQueue) {
		super('Component-PictureSelectWindow');
		
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
		this.Sub("toggleOpen", () => this.toggleOpen());
		this._aspectRatio = this.getAspectRatio(this.aspectRatio);

		document.getElementById("inputPath").addEventListener("click", () => {
			this.removeSelecetedClientFiles();
		})
	}

	detached() {
		super.detached();
	}
	

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	private onWindowReady(window) {
		this._window = window;
		this._window.center();
		this._window.close();
	}


	private toggleOpen() {
		if (this._window.options.visible) {
			this._window.close();
		} else {
			this._window.center();
			//set selected server buttons
			this._currentIndex = 0;
			this.SetSelectedFilesIndexInUI("server-object", this.selectedServerObjectNameList);
			this.SetSelectedFilesIndexInUI("client-object", this.selectedClientObjectList);
			this._window.open();
		}
	}

	private SetSelectedFilesIndexInUI(elementClass, arr) {
		//set selected server buttons
		var btns = document.getElementsByClassName(elementClass) as HTMLCollectionOf<HTMLElement>;
		for (let i = 0; i < btns.length; i++) {
			btns[i].getElementsByClassName("indicator")[0].innerHTML = null;
			btns[i].dataset.select = "false";
		}

		if (arr) {
			for (let i = 0; i < btns.length; i++) {
				var found = false;
				var sortIndex = 0;
				for (let n = 0; n < arr.length; n++) {
					if (arr[n].type == "server" && btns[i].dataset.name == arr[n].data ||
						this.selectedFiles != null && arr[n].type == "client" && this.selectedFiles[btns[i].dataset.name] == arr[n].data) {
						this._currentIndex = this._currentIndex + 1;
						sortIndex = arr[n].index
						found = true;
						break;
					}
				}
				if (found) {
					btns[i].getElementsByClassName("indicator")[0].innerHTML = sortIndex.toString();
					btns[i].dataset.select = "true";
				} 
			}
		}
	}

	private removeSelecetedClientFiles() {
		var clientbtns = document.getElementsByClassName("client-object") as HTMLCollectionOf<HTMLElement>;
		for (var i = 0; i < clientbtns.length; i++) {
			this.toggleSelectState(clientbtns[i]);
		}
	}

	private toggleSelectState(button) {
		if (button.dataset.select == "false") {
			if (this.checkInputLimit()) {
				this._currentIndex = this._currentIndex + 1;
				button.getElementsByClassName("indicator")[0].innerHTML = this._currentIndex.toString();
				button.dataset.select = "true";
			}
		} else {
			button.dataset.select = "false";
			this._currentIndex = this._currentIndex - 1;
			var oldIndex = Number.parseInt(button.getElementsByClassName("indicator")[0].innerHTML);
			button.getElementsByClassName("indicator")[0].innerHTML = null;
			var allIndicators = document.getElementsByClassName("indicator");
			for (var i = 0; i < allIndicators.length; i++) {
				var currIndex = Number.parseInt(allIndicators[i].innerHTML);
				if (currIndex > oldIndex) {
					allIndicators[i].innerHTML = (currIndex - 1).toString();
				}
			}
		}
	}

	private confirmObjectList() {
		this.selectedServerObjectNameList = [];
		this.selectedClientObjectList = [];

		var serverObjects = document.querySelectorAll(".server-object");
		serverObjects.forEach(object => {
			if (object.getAttribute("data-select") == "true") {
				this.selectedServerObjectNameList.push({
					index: Number.parseInt(object.getElementsByClassName("indicator")[0].innerHTML),
					type: "server",
					data: object.getAttribute("data-name")
				});
			}
		});

		
		var clientObjects = document.querySelectorAll(".client-object");
		clientObjects.forEach(object => {
			if (object.getAttribute("data-select") == "true") {
				this.selectedClientObjectList.push({
					index: Number.parseInt(object.getElementsByClassName("indicator")[0].innerHTML),
					type: "client",
					data: this.selectedFiles[object.getAttribute("data-name")]
				});
			}	
		});
		this._window.close();
	}

	private checkInputLimit():boolean {
		var selectedObjects = 0;
		var serverObjects = document.querySelectorAll(".server-object");
		serverObjects.forEach(object => {
			if (object.getAttribute("data-select") == "true") {
				selectedObjects++;
			}
		});
		var clientObjects = document.querySelectorAll(".client-object");
		clientObjects.forEach(object => {
			if (object.getAttribute("data-select") == "true") {
				selectedObjects++;
			}
		});
		if ((this.inputLimit != null && selectedObjects < this.inputLimit) ||
			this.inputLimit == null) {
			return true;
		} else {
			this.Alert_Warn(this._i18n.tr("picture_select_window.input_limit_reached_alert") + this.inputLimit)
			return false;
		}
	}

	private getAspectRatio(aspectRatioString: string): number {
		var aspectRatio = 0;
		/*this.Log_UserInput("aspectRatioString Input: " + aspectRatioString);*/
		if (aspectRatioString.split(':').length == 2) {
			var widthString = aspectRatioString.split(':')[0];
/*			this.Log_UserInput(widthString);*/
			var width = parseInt(widthString);
			if (isNaN(width)) {
				this.Log_Error("getAspectRatio(): invalid aspect Ratio Input")
				return 0;
			}

			var heightString = aspectRatioString.split(':')[1];
			/*this.Log_UserInput(heightString);*/
			var height = parseInt(heightString);
			if (isNaN(height)) {
				this.Log_Error("getAspectRatio(): invalid aspect Ratio Input")
				return 0;
			}

			if (width > 0 && height > 0) {
				aspectRatio = width / height;
			} else {
				this.Log_Error("getAspectRatio(): invalid aspect Ratio Input = 0")
			}
		}
		if (aspectRatio == 0) {
			this.Log_Error("getAspectRatio(): invalid aspect Ratio Input")
		}
/*		this.Log_UserInput("getAspectRatio = " + aspectRatio);*/
		return aspectRatio
	}

	checkRatio() {
		var clientObjects = document.querySelectorAll(".client-object");
		this.Log_UserInput(clientObjects.length);
		var that = this;
		for (var i = 0; i < this.selectedFiles.length; i++) {
				var image = new Image();
				image.addEventListener('load', function () {
					var calculatedRatio = this.width / this.height;
						var ratioDiff = Math.abs(that._aspectRatio - calculatedRatio);
					if (ratioDiff > 0.2) {
						that.removeInvalidRatioFile();
					}
				});
			image.src = URL.createObjectURL(this.selectedFiles[i]);
		}
	}

	removeInvalidRatioFile() {
		this.selectedFiles = null;
		$("#inputPath").val(null);
		this.Alert_Error(this._i18n.tr("picture_select_window.aspect_ratio_alert") + this.aspectRatio);
	}

	
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	
	//#endregion Impl
}

