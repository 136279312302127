import { Router, RouterConfiguration } from "aurelia-router";
import { autoinject } from 'aurelia-dependency-injection';

import { BaseClass } from '../core/base-class';
import { IAlert } from '../core/interfaces/IAlert';
import { AuthorizeStep } from "../models/AuthorizeStep";

import './master.scss';
import { AuthService } from "../services/auth-service";

@autoinject()
export class Master extends BaseClass {
    //#region Variables

	static viewmodel: any;

    //#region -------------------------------------       Bindables        -------------------------------------
    //@bindable variable: datatype(boolean,string,...);
    //#endregion -------------------------------------       Bindables        -------------------------------------

    //#region -------------------------------------       Public        -------------------------------------
    //#endregion -------------------------------------       Public        -------------------------------------

    //#region -------------------------------------       Protected     -------------------------------------
    //#endregion -------------------------------------       Protected     -------------------------------------

    //#region -------------------------------------       Private       -------------------------------------
	//Alerting
	private _staticNotification: any;
    //#endregion -------------------------------------       Private       -------------------------------------

    //#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _authService: AuthService) {
		super('Layout-Master');
		Master.viewmodel = this;

		var that = this;
		window.onunload = async function () {
			if (that._authService.IsLoggedIn()) {
				await that._authService.Logout();
			}
		}
		//$(window).on("beforeunload", async function  () {
		//	if (that._authService.IsLoggedIn()) {
		//		await that._authService.Logout();
		//		//that._router.navigateToRoute("login", that._urlParams);
		//	}
		//	return;
		//})
    }

	configureRouter(config: RouterConfiguration) {
        config.title = "AREC Website";
		config.addAuthorizeStep(AuthorizeStep);

		const handleUnknownRoutes = (instruction) => {
			return { route: 'error-404', moduleId: '../views/error/404/error-404', title: "Error-404", settings: { auth: false } };
		}

		config.mapUnknownRoutes(handleUnknownRoutes);

		config.map([
			{ route: ['', 'home'], name: "home", moduleId: "../views/home/home", title: "Home", settings: { auth: false } },
			{ route: ["login"], name: "login", moduleId: '../views/auth/login', title: 'Login', settings: { auth: false } },
			{ route: ["dashboard"], name: "dashboard", moduleId: '../views/dashboard/dashboard', title: 'Dashboard', settings: { auth: true, isnav: true } },
			{ route: ["impressum"], name: "impressum", moduleId: '../views/impressum/impressum', title: 'Impressum', settings: { auth: false } },
			{ route: ["privacy"], name: "privacy", moduleId: '../views/privacy/privacy', title: 'Privacy', settings: { auth: false } },
			{ route: ["support"], name: "support", moduleId: '../views/support/support', title: 'Support', settings: { auth: false } },
			{ route: ["career"], name: "career", moduleId: '../views/career/career', title: 'Career', settings: { auth: false } },
			{ route: ["contact"], name: "contact", moduleId: '../views/contact/contact', title: 'Contact', settings: { auth: false } },
			{ route: ["history"], name: "history", moduleId: '../views/history/history', title: 'History', settings: { auth: false } },
			{ route: ["press"], name: "press", moduleId: '../views/press/press', title: 'Press', settings: { auth: false } },
			{ route: ["branche"], name: "branche", moduleId: '../views/branche/branche', title: 'Branche', settings: { auth: false } },
			{ route: ["product"], name: "product", moduleId: '../views/product/product', title: 'Product', settings: { auth: false } },
			{ route: ["competence/:id?"], name: "competence", moduleId: '../views/competence/competence', title: 'Competence', settings: { auth: false } },
			{ route: ["history/edit/:id?"], name: "history_edit", moduleId: '../views/history/edit/edit', title: 'History Edit', settings: { auth: true } },
			{ route: ["joboffer/edit/:id?"], name: "joboffer_edit", moduleId: '../views/career/edit/edit', title: 'JobOffer Edit', settings: { auth: true } },
			{ route: ["branche/edit/:id?"], name: "branche_edit", moduleId: '../views/branche/edit/edit', title: 'Branche Edit', settings: { auth: true } },
			{ route: ["product/edit/:id?"], name: "product_edit", moduleId: '../views/product/edit/edit', title: 'Product Edit', settings: { auth: true } },
			{ route: ["press/edit/:id?"], name: "press_edit", moduleId: '../views/press/edit/edit', title: 'Press Edit', settings: { auth: true } },
			{ route: ["competence/edit/:id?"], name: "competence_edit", moduleId: '../views/competence/edit/edit', title: 'Competence Edit', settings: { auth: true } },
		]);
    }
    //#endregion CTOR / INITIALIZE

    //#region Events   

    //#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		this.writeArecLogo();
		super.attached();

		this.Sub('alerting_pipe', message => { this.showStaticNotification(message); });
		window.addEventListener("scroll", this.scroll);
		document.getElementById("sidemenu-scroll-to-top").addEventListener("click", this.scrollToTop);

		//check browser
		if (navigator.userAgent.indexOf("Chrome") == -1 &&
			navigator.userAgent.indexOf("Firefox") == -1 &&
			navigator.userAgent.indexOf("Edg") == -1 &&
			navigator.userAgent.indexOf("Safari") == -1) {
			this.CallAfterUIUpdate(() => {
				alert(this._i18n.tr("browserincompatibel"));
			}, 2000);
		}
    }

	detached() {
		window.removeEventListener("scroll", this.scroll);
		document.getElementById("sidemenu-scroll-to-top").removeEventListener("click", this.scrollToTop);
		super.detached();
	}

    //#endregion -------------------------------------       Framework-Events       -------------------------------------

    //#region -------------------------------------       Custom-Events       -------------------------------------
	scroll() {
		var that = Master.viewmodel;
		document.getElementsByTagName("body")[0].setAttribute("data-scrollY", window.scrollY.toString());
		if (window.scrollY != 0) {
			that.sidemenu.IsIntersecting();
		} else {
			that.sidemenu.IsNotIntersecting();
		}
	}

	scrollToTop() {
		window.scrollTo({ left: 0, top: 0, behavior: "smooth"});
	}
    //#endregion -------------------------------------       Custom-Events       -------------------------------------

    //#endregion Events

	//#region Impl  
	showStaticNotification(alert_message: IAlert) {
		//set duration
		this._staticNotification.options.autoHideAfter = alert_message.autoHideAfter_duration;
		//show alert
		this._staticNotification.show(alert_message.message, alert_message.type);

		let container = $(this._staticNotification.options.appendTo);
        container.scrollTop(container[0].scrollHeight);
	}

	private writeArecLogo() {
		console.log("  .d8b.  d8888b. d88888b  .o88b. ", '\n',
					"d8' `8b 88  `8D 88'     d8P  Y8 ", '\n',
					"88ooo88 88oobY' 88ooooo 8P      ", '\n',
					"88ooo88 88oobY' 88ooooo 8P      ", '\n',
					"88~~~88 88`8b   88~~~~~ 8b      ", '\n',
					"88   88 88 `88. 88.     Y8b  d8 ", '\n',
					"88   88 88 `88. 88.     Y8b  d8 ", '\n',
					"YP   YP 88   YD Y88888P  `Y88P' "		);
	}
    //#endregion Impl
}

