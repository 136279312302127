import { autoinject, DOM } from "aurelia-framework";

import { BaseClass } from '../../core/base-class';
import { LoginContext } from '../../models/LoginContext';
import { Branche } from "../branche/branche";

import './home.scss';

@autoinject()
export class Home extends BaseClass {
	//#region Variables

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext) {
		super('View-Home');
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");
	}

	attached() {
		super.attached();
		this.Log_Info("Preload all Competence Images");
		//TODO
		//for (var i = 0; i < Branche.branchesStatic.length; i++) {
		//	for (var n = 0; n < Branche.branchesStatic[i].images.length; n++) {
		//		this.preloadImage(Branche.branchesStatic[i].images[n].imgpath);
		//	}
		//}
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	onClick_Down() {
		this.scrollToElementById("home-overview");
	}
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  

    //#endregion Impl
}