import { autoinject, DOM } from "aurelia-framework";

import { BaseClass } from '../../core/base-class';
import { LoginContext } from '../../models/LoginContext';
import { INews } from "../../interfaces/INews";
import { PressService } from "../../services/project/press-service";

import './press.scss';

@autoinject()
export class Press extends BaseClass {
	//#region Variables

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------
	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	_newslist: INews[] = [];
	_isLoading: boolean = true;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext,
				private _pressService: PressService) {
		super('View-Press');

		_pressService.GetAll().then((response: INews[]) => {
			this._newslist = response;
		}).finally(() => {
			this._isLoading = false;
		});
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");
	}
		

	attached() {
		super.attached();
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
    //#endregion Impl
}