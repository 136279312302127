import { inject, bindable } from 'aurelia-framework';
import { gsap } from 'gsap';
import { random } from 'gsap/all';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { BaseClass } from '../../core/base-class';

import './arec-intropic.scss';

gsap.registerPlugin(MotionPathPlugin);

@inject(Element)
export class ArecIntropicCustomElement extends BaseClass {
	//#region Variables
	//#region -------------------------------------       Bindables        -------------------------------------

	//#endregion -------------------------------------       Bindables        -------------------------------------

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	private displaynone: string;
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	_elem: HTMLElement;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(elem) {
		super('Layout-ArecIntropic');
		this._elem = elem;
		this.displaynone = "d-none"
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();

		if (!window.location.href.includes('#')) {
			this.animateSVG();
		}
		
	}

	detached() {
		super.detached();
		
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	animateSVG() {
		var objs = this._elem.getElementsByClassName("cls-8");
		var val = 0;

		for (var i = 0; i < objs.length; i++) {
			var randomdelay = gsap.utils.random(1, 150);
			//toggle cause of to many animated elements 
			if (val == 0) {
				//animate element with path and opacity
				var randomNumX1 = gsap.utils.random(50, -100),
					randomNumY1 = gsap.utils.random(50, -100),
					randomNumX2 = gsap.utils.random(25, -50),
					randomNumY2 = gsap.utils.random(25, -50);
				gsap.set(objs[i], { x: randomNumX1, y: randomNumY1, opacity: 0 });
				gsap.to(objs[i], {
					motionPath: {
						path: [{ x: randomNumX2, y: randomNumY2 }, { x: 0, y: 0 }],
						curviness: 1
					},
					opacity: 1,
					delay: randomdelay / 100,
					duration: 3
				})
			} else {
				//animate element with delayed opacity
				gsap.set(objs[i], { opacity: 0 });
				gsap.to(objs[i], {
					opacity: 1,
					delay: (randomdelay / 100) + 2.5,
					duration: 2
				})
			}

			val++;
			if (val == 4) {
				val = 0;
			}
		}

		gsap.set(".cls-61", { opacity: 0 });
		gsap.set(".cls-63", { opacity: 0 });
		gsap.to(".cls-61", { opacity: 1, duration: 1, delay: 4.5 });
		gsap.to(".cls-63", { opacity: 1, duration: 1, delay: 4.5 });

		var tl = gsap.timeline({
			delay: 5.3,
			onComplete: () => {
				gsap.to("#letter-a", { fill: "#d5d5d5", delay: 1, duration: 1 });
				gsap.to("#letter-r", { fill: "#d5d5d5", delay: 1, duration: 1 });
				gsap.to("#letter-e", { fill: "#d5d5d5", delay: 1, duration: 1 });
				gsap.to("#letter-c", { fill: "#d5d5d5", delay: 1, duration: 1 });
			}
		});
		tl.to("#letter-a", { fill: "rgb(33,74,144)", duration: 0.75 });
		tl.to("#letter-r", { fill: "rgb(33,74,144)", duration: 0.75 });
		tl.to("#letter-e", { fill: "rgb(33,74,144)", duration: 0.75 });
		tl.to("#letter-c", { fill: "rgb(33,74,144)", duration: 0.75 });
	}

	OnChange() {
		this.Log_UserInput("change svg");
		if (this.displaynone == "d-block") {
			this.displaynone = "d-none";
		} else {
			this.displaynone = "d-block";
		}
	}
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	//#endregion Impl
}

