import { autoinject, DOM } from "aurelia-framework";
import { BranchProductItem } from "../../components/arec-branch-product-viewer/arec-branch-product-viewer";

import { BaseClass } from '../../core/base-class';
import { IProduct } from "../../interfaces/IProduct";
import { LoginContext } from '../../models/LoginContext';
import { ProductService } from "../../services/project/product-service";

import './product.scss';

@autoinject()
export class Product extends BaseClass {
	//#region Variables

	//#region -------------------------------------       Public        -------------------------------------

	//static productsStatic = [
	//	new BranchProductItem("IMES", null, "fas fa-code", [
	//		new BranchProductImage("Modular aufgebautes Produktionsleitsystem f&uumlr die Verzinkungsanlagen.", "./dist/assets/images/history_2011_simu.jpg")
	//	]),
	//	new BranchProductItem("AVF-Server", "(AREC Visualization Foundation)", "fas fa-code", [
	//		new BranchProductImage("Einfach zu verwaltende Anbindung an individuelle Steuerungen auf Basis von WCF und .NET", "./dist/assets/images/history_2011_simu.jpg")
	//	]),
	//];
	//products: BranchProductItem[] = [];

	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	_productList: IProduct[] = []
	_brancheProductList: BranchProductItem[] = []
	_isLoading: boolean = true;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext,
				private _productService: ProductService	) {
		super('View-Product');

		_productService.GetAll().then((response: IProduct[]) => {
			this._productList = response;
			let tmpList: BranchProductItem[] = [];
			for (var product of this._productList) {
				var iconlinks = [];
				var branchearr = [];
				product.Branches.forEach((item) => {
					branchearr.push({ id: item.BrancheID, link: "/#/branche?id=" + item.BrancheID });
				});
				iconlinks.push({ type: "branche", data: branchearr });

				var tmp = new BranchProductItem(
					product.ID,
					"product.items." + product.ID + ".title",
					product.Subtitle ? "product.items." + product.ID + ".subtitle" : null,
					"product.items." + product.ID + ".description",
					null,
					JSON.parse(product.JsonImagePaths) as string[],
					iconlinks,
					product.VideoPath
				);
				tmpList.push(tmp);
			}
			this._brancheProductList = tmpList;
		}).finally(() => {
			this._isLoading = false;
		});
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");
	}


	attached() {
		super.attached();
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  

	//#endregion Impl
}
