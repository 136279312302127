import { autoinject } from "aurelia-framework";
import { HubConnectionBuilder, LogLevel, HubConnection, IHttpConnectionOptions, HttpTransportType, HttpError } from '@microsoft/signalr';

import { BaseClass } from '../../core/base-class';
import { LoginContext } from '../../models/LoginContext';

@autoinject()
export class BaseHub extends BaseClass {
    //#region Variables

    //#region -------------------------------------       Public        -------------------------------------
    //#endregion -------------------------------------       Public        -------------------------------------

    //#region -------------------------------------       Protected     -------------------------------------
	protected _connection: HubConnection;
    //#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	private _reconnectTimeout: any;
	private _bShutdown: boolean;
    //#endregion -------------------------------------       Private       -------------------------------------

    //#endregion Variables

    //#region CTOR / INITIALIZE
	constructor(protected _loginContext:LoginContext, protected url: string, protected logName: string) {
		//only one trigger 
		super(logName);

		// Inject AccessToken to signalR
		let options: IHttpConnectionOptions = {
			accessTokenFactory: () => this._loginContext.token,
			transport: HttpTransportType.LongPolling
		};
		//create new signalr hub
		this._connection = new HubConnectionBuilder()
			.withUrl(this.url, options)
			.configureLogging(LogLevel.Warning)
			.build();

		//set all needed subscriptions
		this.settingsServerClient();

		this._bShutdown = false;
    }
    //#endregion CTOR / INITIALIZE

    //#region Events   

    //#region -------------------------------------       Custom-Events       -------------------------------------

	public async Start(): Promise<boolean> {
		//every reconnect and init is this triggered
		this.Log_Info("[start]: try connect");
		this.clearReconnectionTimeout();
		this._bShutdown = false;
		
		//start the hub
		try {
			this.Log_Info("[start]: hub state -> " + this._connection.state);
			await this._connection.start();
			this.Log_Info("[start]: connected");
			//start success event (overrideable)
			this.start_success();
		} catch (error) {
			let errorMsg: string = error.toString();
			this.Log_Error("[start]: connection failed: " + errorMsg);
			if (errorMsg.includes("Unauthorized")) {
				this.Log_Error(error);
				this.Alert_Warn("Deine Anmeldung ist abgelaufen. Bitte melde dich neu an!");
				this.Log_Warn("Deine Anmeldung ist abgelaufen. Bitte melde dich neu an!");
				this.Publish("Menu_logout");
			}
			this.serviceStopped();
			return false;
		}
		return true;
	}

	protected start_success() {}

	public async Shutdown() {
		//clear reconnection timer if real close is triggered
		this.clearReconnectionTimeout();
		this._bShutdown = true;
		await this.stop();
		this.Log_Warn("Shutdown");
	}

	protected async stop() {
		await this._connection.stop()
			.then(() => {
				this.Log_Info("[stop]: disconnected")
			}).catch((err) => {
				this.Log_Error("[stop]: disconnection failed: " + err.toString())
			});
	}

	//CLIENT -> SERVER

	//SERVER -> CLIENT
	//set all signalr subscribtions and publish it with the aurelia eventaggregator
	protected settingsServerClient() {
		this._connection.onclose((data) => {
			this.serviceStopped();
			this.Log_Warn("Disconnected");
		});
	}

    //#endregion -------------------------------------       Custom-Events       -------------------------------------

    //#endregion Events

    //#region Impl  

	protected serviceStopped() {
		if (!this._bShutdown) {
			if (this._reconnectTimeout == null) {
				//reconnect
				this._reconnectTimeout = setTimeout(() => {
					this.Start();
				}, 10000);
			}
		}
	}

	private clearReconnectionTimeout() {
		if (this._reconnectTimeout != null) {
			clearTimeout(this._reconnectTimeout)
			this._reconnectTimeout = null;
		}
	}
    //#endregion Impl
}