import { autoinject, singleton } from "aurelia-framework";

import { BaseClass } from '../core/base-class';

@singleton()
@autoinject()
export class StorageService extends BaseClass {
    //#region Variables

    //#region -------------------------------------       Public        -------------------------------------

	public get isLoggedIn(): boolean {
		return sessionStorage.getItem('username') ? true : false;
	}

    public get Username() {
        return sessionStorage.getItem('username');
	}

    //#endregion -------------------------------------       Public        -------------------------------------

    //#region -------------------------------------       Protected     -------------------------------------
    //#endregion -------------------------------------       Protected     -------------------------------------

    //#region -------------------------------------       Private       -------------------------------------
    //#endregion -------------------------------------       Private       -------------------------------------

    //#endregion Variables

    //#region CTOR / INITIALIZE
    constructor() {
		super('Service-Storage');
    }
    //#endregion CTOR / INITIALIZE

    //#region Events   

    //#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
    }

	detached() {
		super.detached();
    }

    //#endregion -------------------------------------       Framework-Events       -------------------------------------

    //#region -------------------------------------       Custom-Events       -------------------------------------

    //#endregion -------------------------------------       Custom-Events       -------------------------------------

    //#endregion Events

    //#region Impl  
	
    //#endregion Impl
}