import { autoinject, bindable } from 'aurelia-framework';
import { BaseClass } from '../core/base-class';

import './master-heading.scss';

@autoinject()
export class MasterHeadingCustomElement extends BaseClass {
	@bindable title: string;
	//#region Variables
	//#region -------------------------------------       Bindables        -------------------------------------
	//@bindable variable: datatype(boolean,string,...);
	//#endregion -------------------------------------       Bindables        -------------------------------------

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	homeViewName: string;
	homePath: string;
	currentViewName: string;
	currentPath: string;
	//Alerting
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor() {
		super('Layout-MasterHeading');
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
		this.updateContentOverview();
		let that = this;
		window.addEventListener('hashchange', function () {
			that.updateContentOverview();
		});
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl 
	updateContentOverview() {
		this.CallAfterUIUpdate(() => {
			this.homePath = "/#/home";
			this.homeViewName = this._i18n.tr("navbar.home");
			this.currentPath = "/#/" + this._router.currentInstruction.config.name;
			this.currentViewName = this._i18n.tr("navbar." + this._router.currentInstruction.config.name);
		});
	}

	//#endregion Impl
}

