import "isomorphic-fetch";
import { Aurelia, PLATFORM, LogManager } from "aurelia-framework";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/all'

import '@progress/kendo-ui/js/kendo.notification.min';
import '@progress/kendo-ui/css/web/kendo.common.min.css';
import '@progress/kendo-ui/css/web/kendo.bootstrap.min.css';

import XHR from 'i18next-xhr-backend';
import { ConsoleAppender } from './app/core/logger/console-appender';

declare const IS_DEV_BUILD: boolean; // The value is supplied by Webpack during the build

let winObj: any = <any>window;
//Export jQuery to window object
import * as $ from 'jquery';
winObj['jQuery'] = $;
winObj['$'] = $;

export function configure(aurelia: Aurelia) {
	//#region -------------------------------------       Logger Settings       -------------------------------------
	LogManager.addAppender(new ConsoleAppender());
	LogManager.setLevel(LogManager.logLevel.info);
	//#endregion -------------------------------------       Logger Settings       -------------------------------------		

    aurelia.use
        .standardConfiguration()
        .defaultBindingLanguage()
		.defaultResources()
		.plugin(PLATFORM.moduleName('aurelia-resize'))
        .plugin(PLATFORM.moduleName('aurelia-kendoui-bridge'))
        .plugin(PLATFORM.moduleName('aurelia-i18n'), (instance) => {
            // register backend plugin (interface for JavaScript)
			instance.i18next.use(XHR);
            // adapt options to your needs (see http://i18next.com/docs/options/)
            instance.setup({
                backend: {
					loadPath: () => {
						return './dist/locales/{{lng}}/{{ns}}.json?nocache=' + (new Date()).getTime()
					},
				},
				detection: {
					caches: []
				},
				lng: 'de',
                attributes: ['t', 'i18n'],
				fallbackLng: 'en',
				debug: false,
			});
		});

    aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName("app/layout/master")));
}
