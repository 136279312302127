import { TaskQueue, autoinject, DOM } from "aurelia-framework";

import { BaseClass } from '../../../core/base-class';
import { LoginContext } from '../../../models/LoginContext';
import { PressInput } from "../../../models/PressInput";
import { PressTextInput } from "../../../models/PressTextInput";
import { PressService } from "../../../services/project/press-service";

import './edit.scss';

@autoinject()
export class Edit extends BaseClass {
	//#region Variables
	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	private _indexOfEditedObj = null;
	private _pressTextInput: PressTextInput[];
	private _pressInput: PressInput;
	private _isLoading: boolean = true;
	private _videoList: string[];
	private _selectedServerVideoList: any[] = [];
	private _selectedClientVideoList: any[] = [];
	
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext,
				private _taskQueue: TaskQueue,
				private _pressService: PressService) {
		super('View-Edit_Press');
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");
		this._pressService.GetVideoList().then((response) => {
			this._videoList = response;
		});
	}

	activate(params) {
		this._indexOfEditedObj = params.id;

		this._pressTextInput = [];
		this._pressTextInput.push(new PressTextInput("de"));
		this._pressTextInput.push(new PressTextInput("en"));
		this._pressInput = new PressInput(this._pressTextInput, null, null);

		//if '_indexOfEditedObj' has an value, the user will edit an existing Object
		if (this._indexOfEditedObj != null) {
			this._isLoading = true;
			this._pressService.Get(this._indexOfEditedObj).then((resp) => {
				this._pressInput.videoPath = resp.VideoPath;
				//adding selected video to imagepicker and select the right video
				this._selectedServerVideoList.push({
					index: 1,
					type: "server",
					data: this._pressInput.videoPath.substring(this._pressInput.videoPath.lastIndexOf("/") + 1)
				});
				//store current translation language
				var oldLocale = this._i18n.getLocale();
				for (var i = 0; i < this._pressInput.TextItemList.length; i++) {
					var lang = this._pressInput.TextItemList[i].language;
					// translation language
					this._i18n.setLocale(lang);
					//set title
					this._pressInput.TextItemList[i].title = this._i18n.tr("press.items." + resp.ID + ".title");
					//set description
					this._pressInput.TextItemList[i].description = this._i18n.tr("press.items." + resp.ID + ".description");
				}
				//set date
				this._pressInput.date = resp.Date;
				//set old translation language
				this._i18n.setLocale(oldLocale);
				this._isLoading = false;
			});
		} else {
			this._isLoading = false;
		}
	}

	attached() {
		super.attached();
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  

	redirect(newTab, newparams) {
		window.scrollTo(0, 0);
		this._router.navigateToRoute(newTab, newparams);
	}

	create() {
		var result = this.checkDataAndGenerateFormData();
		if (result != null) {
			this._pressService.Create(result).then((result) => {
				if (result) this.redirect("dashboard", { "pointer": "press" });
			});
		}
	}

	update() {
		var result = this.checkDataAndGenerateFormData();
		if (result != null) {
			this._pressService.Update(this._indexOfEditedObj, result).then((result) => {
				if (result) this.redirect("dashboard", { "pointer": "press" });
			});
		}
	}

	checkDataAndGenerateFormData() {
		
		//store new Product
		var newFormData = new FormData();
		for (var i = 0; i < this._selectedClientVideoList.length; i++) {
			newFormData.append("rawclientfiles", this._selectedClientVideoList[i].data);
		}
		newFormData.append("clientfilesJSON", JSON.stringify(this._selectedClientVideoList));
		newFormData.append("serverfilesJSON", JSON.stringify(this._selectedServerVideoList));
		newFormData.append("input", JSON.stringify(this._pressInput));
		return newFormData;
	}
    //#endregion Impl
}


