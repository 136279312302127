import { TaskQueue, autoinject, DOM } from "aurelia-framework";
import { ready, newInstance, BrowserJsPlumbInstance } from "@jsplumb/browser-ui"

import { BaseClass } from '../../../core/base-class';
import { IBranche } from "../../../interfaces/IBranche";
import { CompetenceInput } from "../../../models/CompetenceInput";
import { CompetenceTextInput } from "../../../models/CompetenceTextInput";
import { JSPlumbRelation } from "../../../models/JSPlumb_Relation";
import { LoginContext } from '../../../models/LoginContext';
import { BrancheService } from "../../../services/project/branche-service";
import { CompetenceService } from "../../../services/project/competence-service";

import './edit.scss';



@autoinject()
export class Edit extends BaseClass {
	//#region Variables
	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	private _indexOfEditedObj = null;
	private _competenceTextInput: CompetenceTextInput[];
	private _competenceInput: CompetenceInput;
	private _branches: IBranche[];
	private _relCompetenceBranche: JSPlumbRelation[] = [];
	private _isLoading: boolean = true;
	private _pictureList: string[];
	private _selectedServerPictureList: any[] = [];
	private _selectedClientPictureList: any[] = [];
	private _jsplumb: BrowserJsPlumbInstance;
	
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext,
				private _taskQueue: TaskQueue,
				private _competenceService: CompetenceService,
				private _brancheService: BrancheService) {
		super('View-Edit_Competence');

		this._competenceService.GetPictureList().then((response) => {
			this._pictureList = response;
		});
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");

		var that = this;
		window.addEventListener("resize", function (e) {
			that._jsplumb.repaintEverything();
		});
	}

	activate(params) {
		this._indexOfEditedObj = params.id;

		this._competenceTextInput = [];
		this._competenceTextInput.push(new CompetenceTextInput("de"));
		this._competenceTextInput.push(new CompetenceTextInput("en"));
		this._competenceInput = new CompetenceInput(this._competenceTextInput, null);

		//if '_indexOfJobOffer' has an value, the user will edit an existing JobOffer
		if (this._indexOfEditedObj != null) {
			this._isLoading = true;
			this._competenceService.Get(this._indexOfEditedObj).then((resp) => {
				this._competenceInput.ImagePath = resp.ImagePath;
				//adding selected image to imagepicker and select the right image
				this._selectedServerPictureList.push({
					index: 1,
					type: "server",
					data: this._competenceInput.ImagePath.substring(this._competenceInput.ImagePath.lastIndexOf("/") + 1)
				});
				this._competenceInput.OrderNumber = resp.OrderNumber;
				//store current translation language
				var oldLocale = this._i18n.getLocale();
				for (var i = 0; i < this._competenceInput.TextItemList.length; i++) {
					var lang = this._competenceInput.TextItemList[i].language;
					// translation language
					this._i18n.setLocale(lang);
					//set title
					this._competenceInput.TextItemList[i].title = this._i18n.tr("competence.items." + resp.ID + ".title");
					//set description
					this._competenceInput.TextItemList[i].description = this._i18n.tr("competence.items." + resp.ID + ".description");
					//set details
					for (var n = 0; n < resp.DetailLength; n++) {
						this._competenceInput.TextItemList[i].details.push(this._i18n.tr("competence.items." + resp.ID + ".details."+ n));
					}
				}
				for (var i = 0; i < resp.Branches.length; i++) {
					this._relCompetenceBranche.push(new JSPlumbRelation(resp.ID, resp.Branches[i].BrancheID))
				}
				//set old translation language
				this._i18n.setLocale(oldLocale);
				this.loadBrancheList();
			});
		} else {
			this.loadBrancheList();
		}
	}

	attached() {
		super.attached();
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------

	OnNewDetail() {
		for (var i = 0; i < this._competenceInput.TextItemList.length; i++) {
			this._competenceInput.TextItemList[i].details.push(null);
		}
		this.CallAfterUIUpdate(() => {
			this._jsplumb.repaintEverything();
		});
	}

	OnDetailDelete(index) {
		for (var i = 0; i < this._competenceInput.TextItemList.length; i++) {
			this._competenceInput.TextItemList[i].details.splice(index, 1);
		}
		this.CallAfterUIUpdate(() => {
			this._jsplumb.repaintEverything();
		});
	}
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	private loadBrancheList() {
		this._brancheService.GetAll().then((resp) => {
			this._branches = resp;
			this._isLoading = false;
			this.CallAfterUIUpdate(() => {
				this.jsPlumb_Ready();
			}, 500);
		});
	}

	jsPlumb_Ready() {
		//jsplumb ready
		ready(() => {
			//register default styling and settings
			this._jsplumb = newInstance({
				container: document.getElementById("jsPlumbContainer"),
			})

			this._jsplumb.registerConnectionTypes({
				"default": {
					paintStyle: { stroke: "#004C8A", strokeWidth: 4 },
					hoverPaintStyle: { stroke: "#98B7D4", strokeWidth: 5 },
					cssClass: "connector-normal"
				}
			});

			var sourceEP: any = { anchor: "Left" };
			var targetEP: any = { anchor: "Right", maxConnections: 1};
			var common = {
				paintStyle: { fill: "#004C8A"},
				endpoint: "Rectangle",
				detachable: true,
				connectorPaintStyle: { type: "basic" }
			};
			//define the source and target elements
			this._jsplumb.makeSource(document.getElementById("jsP_source_competence"), sourceEP, common);
			for (var i = 0; i < this._branches.length; i++) {
				this._jsplumb.makeTarget(document.getElementById("jsP_target_branche_" + this._branches[i].ID), targetEP, common);
			}
			//init all connections/relationships
			for (var i = 0; i < this._relCompetenceBranche.length; i++) {
				var con = this._jsplumb.connect({
					target: document.getElementById("jsP_target_branche_" + this._relCompetenceBranche[i].target),
					source: document.getElementById("jsP_source_competence")
				});

				this._relCompetenceBranche[i].conID = con.id;
			}
			//bind to connection and detach events to handle the state
			var that = this;
			this._jsplumb.bind("connection", function (info) {
				var split_Target = info.target.id.split('_')
				var targetID = Number.parseInt(split_Target[3]);
				if (split_Target[2] == "branche") {
					that.checkUniqueAndInsertIfUnique(that._relCompetenceBranche, targetID, info.connection, that._jsplumb);
				}
			});

			this._jsplumb.bind("beforeDetach", function (connection) {
				var index = null;
				for (var i = 0; i < that._relCompetenceBranche.length; i++) {
					if (that._relCompetenceBranche[i].conID == connection.id) {
						index = i;
						break;
					}
				}
				if (index != null) {
					that._relCompetenceBranche.splice(index, 1);
				}
			});
		})
	}

	checkUniqueAndInsertIfUnique(relationList, targetID, connection, jsPlumbInstance) {
		var unique = true;
		for (var i = 0; i < relationList.length; i++) {
			if (relationList[i].target == targetID) {
				unique = false;
				break;
			}
		}
		if (unique) {
			relationList.push(new JSPlumbRelation(this._indexOfEditedObj ? Number.parseInt(this._indexOfEditedObj) : null, targetID, connection.id));
		} else {
			this.removeConnection(jsPlumbInstance, connection);
		}
	}

	removeConnection(instance, connection) {
		instance.destroyConnection(connection);
		for (var i = connection.endpoints.length - 1; i >= 0; i--) {
			instance.destroyEndpoint(connection.endpoints[i]);
		}
	}

	redirect(newTab, newparams) {
		window.scrollTo(0, 0);
		this._router.navigateToRoute(newTab, newparams);
	}

	create() {
		var result = this.checkDataAndGenerateFormData();
		if (result != null) {
			this._competenceService.Create(result).then((result) => {
				if (result) this.redirect("dashboard", { "pointer": "competence" });
			});
		}
	}

	update() {
		var result = this.checkDataAndGenerateFormData();
		if (result != null) {
			this._competenceService.Update(this._indexOfEditedObj, result).then((result) => {
				if (result) this.redirect("dashboard", { "pointer": "competence" });
			});
		}
	}

	checkDataAndGenerateFormData() {
		//check details length
		if (this._competenceInput.TextItemList[0].details.length == 0) {
			this.Alert_Error(this._i18n.tr("competence.edit.entryrequiredfordetail"));
			return null;
		}
		//store new Product
		var newFormData = new FormData();
		for (var i = 0; i < this._selectedClientPictureList.length; i++) {
			newFormData.append("rawclientfiles", this._selectedClientPictureList[i].data);
		}
		newFormData.append("clientfilesJSON", JSON.stringify(this._selectedClientPictureList));
		newFormData.append("serverfilesJSON", JSON.stringify(this._selectedServerPictureList));
		newFormData.append("input", JSON.stringify(this._competenceInput));
		newFormData.append("relbranchecompetenceJSON", JSON.stringify(this._relCompetenceBranche));
		return newFormData;
	}
    //#endregion Impl
}


