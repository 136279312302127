import { LogManager, autoinject } from 'aurelia-framework';
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { I18N } from 'aurelia-i18n';
import { Container } from 'aurelia-dependency-injection';
import { Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';

import { IAlert } from './interfaces/IAlert';
import { Alert } from "./helper_classes/alerting/alert"; 

const AUTOHIDE_DURATION = 5000;

@autoinject()
export class BaseClass {
    //#region Variables
    //#region -------------------------------------       Bindables        -------------------------------------
    //@bindable variable: datatype(boolean,string,...);
    //#endregion -------------------------------------       Bindables        -------------------------------------

    //#region -------------------------------------       Public        -------------------------------------
    
    //#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	
	protected _i18n: I18N;
	protected _logger: any;
	protected _urlParams: any;
	protected _router: Router;
    //#endregion -------------------------------------       Protected     -------------------------------------

    //#region -------------------------------------       Private       -------------------------------------
	private _eventAggregator: EventAggregator;
	private _subscritions: Subscription[] = new Array<Subscription>();
	private _windows: Window[] = new Array<Window>();
	private _signaler: BindingSignaler;
    //#endregion -------------------------------------       Private       -------------------------------------

    //#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(protected logName: string) {
		this._eventAggregator = Container.instance.get(EventAggregator);
		this._i18n = Container.instance.get(I18N);
		this._logger = LogManager.getLogger(logName);
		this._router = Container.instance.get(Router);
		this._urlParams = this.getParameters();
		this._signaler = Container.instance.get(BindingSignaler);
	}
    //#endregion CTOR / INITIALIZE


    //#region Events   

    //#region -------------------------------------       Framework-Events       -------------------------------------

	protected attached() {
		this.Log_Info("attached");
	}

	protected detached() {
		//dispose all subscriptions
		while (this._subscritions.length != 0) {
			let sub: Subscription = this._subscritions.pop();
			sub.dispose();
		}
		//close all subscreens
		this.CloseAllSubScreens();
		this.Log_Info("detached");
	}
    //#endregion -------------------------------------       Framework-Events       -------------------------------------

    //#region -------------------------------------       Custom-Events       -------------------------------------

    //#endregion -------------------------------------       Custom-Events       -------------------------------------

    //#endregion Events

    //#region Impl  
	//#region -------------------------------------       EventAggregator       -------------------------------------

	protected Sub(event: string, callback: Function) {
		this._subscritions.push(this._eventAggregator.subscribe(event, (data) => callback(data)));
	}

	protected Publish(event: string, data?: any) {
		this._eventAggregator.publish(event, data);
	}
	
	//#endregion -------------------------------------       EventAggregator       -------------------------------------

	//#region -------------------------------------       Logging       -------------------------------------
	protected Log_Debug(...rest: any[]) {
        this._logger.debug(...rest);
    }

	protected Log_Info(...rest: any[]) {
        this._logger.info(0, ...rest);
    }

	protected Log_UserInput(...rest: any[]) {
        this._logger.info(1, ...rest);
    }

	protected Log_Warn(...rest: any[]) {
        this._logger.warn(...rest);
    }

	protected Log_Error(...rest: any[]) {
        this._logger.error(...rest);
    }
    //#endregion -------------------------------------       Logging       -------------------------------------

	//#region -------------------------------------       Alerting       -------------------------------------
	protected Alert_Success(message: string, duration: number = AUTOHIDE_DURATION) {
		this.ShowAlert(new Alert(message, duration, "success"));
	}

	protected Alert_Info(message: string, duration: number = AUTOHIDE_DURATION) {
		this.ShowAlert(new Alert(message, duration, "info"));
	}

	protected Alert_Warn(message: string, duration: number = AUTOHIDE_DURATION) {
		this.ShowAlert(new Alert(message, duration, "warning"));
	}

	protected Alert_Error(message: string, duration: number = AUTOHIDE_DURATION) {
		this.ShowAlert(new Alert(message, duration, "error"));
	}
	protected ShowAlert(message: IAlert) {
		this._eventAggregator.publish('alerting_pipe', message);
	}
	//#endregion -------------------------------------       Alerting       -------------------------------------

	//#region -------------------------------------       SubScreens       -------------------------------------
	//open a new sub screen
	//ATTENTION: if it not works, lookup if popup blocker is enabled at the current browser
	protected OpenSubScreen(url: string) {
		let win = window.open(url, "SubScreen_" + this._windows.length, "left=1980,top=0,height=600,width=550,screenX=300");
		this._windows.push(win);
		win.moveTo(1980, 100);
		//TODO communicate between pages
		//win.postMessage("hello there!", null);
	}

	//close all sub screens
	protected CloseAllSubScreens() {
		while (this._windows.length > 0) {
			var win = this._windows.pop();
			if (win != null) {
				win.close();
			}
		}
	}

	private getParameters() {
		var jsonParams = {};
		let urlParamsRaw = location.href.substring(location.href.indexOf('?') + 1);
		let params = urlParamsRaw.split('&');

		for (let i = 0; i < params.length; i++) {
			let val = params[i].split("=");
			jsonParams[val[0]] = val[1];
		}
		return jsonParams;
	}
	//#endregion -------------------------------------       SubScreens       -------------------------------------

	//#region -------------------------------------       PreLoading Image       -------------------------------------
	protected preloadImage(url) {
		var img = new Image();
		img.src = url;
		this.Log_Info("Preload Image: ", img);
	}
	//#endregion -------------------------------------       PreLoading Image       -------------------------------------

	//#region -------------------------------------       Scrolling      -------------------------------------
	protected scrollToElementById(elementId, scrollbehavior: ScrollBehavior = "smooth") {
		document.getElementById(elementId).scrollIntoView({ behavior: scrollbehavior });
	}
	//#endregion -------------------------------------       Scrolling       -------------------------------------

	//#region -------------------------------------       Validation Helper      -------------------------------------
	protected ValidationFirstKeyValue(errorJson: any) {
		this.Log_UserInput(errorJson);
		return { key: errorJson.key, value: errorJson.errors[0].errorMessage };
	}
	//#endregion -------------------------------------       Validation Helper       -------------------------------------

	protected signal(name: string) { 
		this._signaler.signal(name);
	}

	//to reordering the call of an function to the end of the runtime execution
	//for ui rendering issues
	protected CallAfterUIUpdate(callback: () => void, time: number = 1) {
		setTimeout(() => {
			callback();
		}, time);
	}

	protected dialogconfirm(title, content) {
		return $("<div></div>").kendoConfirm({
			title: title,
			content: content
		} as kendo.ui.ConfirmOptions).data("kendoConfirm").open()["result"];
	}
    //#endregion Impl
}




