import { autoinject, singleton } from "aurelia-framework";

import { BaseClass } from '../../core/base-class';
import { IBranche } from "../../interfaces/IBranche";
import { ICompetence } from "../../interfaces/ICompetence";
import { LoginContext } from '../../models/LoginContext';
import { CommunicationService } from "../comm-service";
import { LocalesService } from "../locales-service";

@singleton()
@autoinject()
export class BrancheService extends BaseClass {
    //#region Variables

    //#region -------------------------------------       Public        -------------------------------------

    //#endregion -------------------------------------       Public        -------------------------------------

    //#region -------------------------------------       Protected     -------------------------------------
    //#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
// Info @Thomas : Use IoC from Aurelia in constructor with @autoinject and with parameters in constructor. No need for private variable in this case....!
	//private _httpClient: HttpClient;
    //#endregion -------------------------------------       Private       -------------------------------------

    //#endregion Variables

    //#region CTOR / INITIALIZE
	constructor(private _commuService: CommunicationService,
				private _loginContext: LoginContext,
				private _localesService: LocalesService) {
		super('Branche-Service');

		//this._httpClient = new HttpClient();
    }
    //#endregion CTOR / INITIALIZE

    //#region Events   

    //#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
    }

	detached() {
		super.detached();
    }

    //#endregion -------------------------------------       Framework-Events       -------------------------------------

    //#region -------------------------------------       Custom-Events       -------------------------------------

    //#endregion -------------------------------------       Custom-Events       -------------------------------------

    //#endregion Events

	//#region Impl  
	public async GetAll(): Promise<IBranche[]> {
		let response = await this._commuService.getResponse('api/branche/get');

		switch (response.status) {
			case 200:
				let data = JSON.parse(JSON.stringify(await response.json())) as IBranche[];
				return data;
			default:
				this.Log_Error("[Get] failed: [status - " + response.status + "]" + response.statusText);
				break;
		}

		return null;
	}

	public async Get(id: number): Promise<IBranche> {
		let response = await this._commuService.getResponse('api/branche/get?id='+ id);

		switch (response.status) {
			case 200:
				var list = JSON.parse(JSON.stringify(await response.json())) as IBranche[];
				return list[0];
			default:
				this.Log_Error("[Get] failed: [status - "+ response.status + "]" + response.statusText);
				break;
		}

		return null;
	}

	public async Create(input: FormData) {
		this.Log_UserInput("create_Branche");

		let response = await this._commuService.postFileWithResponse('api/branche/create', input, this._loginContext.token);

		switch (response.status) {
			case 200:
				//success
				await this._i18n.i18next.reloadResources();
				this.Alert_Success("Create Branche Input successfully");
				this.Log_UserInput("[create] Create Branche Input successfully!");
				return true;
			case 422:
				//validation error
				var keyValue = this.ValidationFirstKeyValue(await response.json());
				this.Alert_Error(keyValue.value);
				this.Log_Error("[create] failed: [ValidationError status - " + response.status + "] " + keyValue.value);
				break;
			default:
				//error
				this.Log_Error("[create] failed: [status - " + response.status + "]");
				break;
		}
		return false;
	}

	public async Update(id: number, input: FormData) {
		this.Log_UserInput("update_Branche");

		let response = await this._commuService.postFileWithResponse('api/branche/update/' + id, input, this._loginContext.token);

		switch (response.status) {
			case 200:
				//success
				await this._i18n.i18next.reloadResources();
				this.Alert_Success("Update Branche Input successfully");
				this.Log_UserInput("[update] Update Branche Input successfully!");
				return true;
			case 422:
				//validation error
				var keyValue = this.ValidationFirstKeyValue(await response.json());
				this.Alert_Error(keyValue.value);
				this.Log_Error("[update] failed: [ValidationError status - " + response.status + "] " + keyValue.value);
				break;
			default:
				//error
				this.Log_Error("[update] failed: [status - " + response.status + "]");
				break;
		}
		return false;
	}

	public async Delete(id: number): Promise<number> {
		let response = await this._commuService.deleteWithCode('api/branche/delete', id, this._loginContext.token);

		switch (response) {
			case 200:
				let response = await this._localesService.RemoveKey("branche.items." + id);
				break;
			default:
				this.Log_Error("[Delete] failed: [status - " + response + "]");
				break;
		}

		return response;
	}

	public async GetPictureList(): Promise<string[]> {
		let response = await this._commuService.getResponse('api/branche/getpictures', this._loginContext.token);

		switch (response.status) {
			case 200:
				let data = JSON.parse(JSON.stringify(await response.json())) as string[];
				return data;
			default:
				this.Log_Error("[getpictures] failed: [status - " + response.status + "]");
				break;
		}
		return null;
	}

	public async GetVideoList(): Promise<string[]> {
		let response = await this._commuService.getResponse('api/branche/getvideos', this._loginContext.token);

		switch (response.status) {
			case 200:
				let data = JSON.parse(JSON.stringify(await response.json())) as string[];
				return data;
			default:
				this.Log_Error("[getvideo] failed: [status - " + response.status + "]");
				break;
		}
		return null;
	}
    //#endregion Impl
}