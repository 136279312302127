
import { autoinject, bindable } from 'aurelia-framework';

import { BaseClass } from '../../base-class';

@autoinject()
export class CoreKeyswitchCustomElement extends BaseClass {
	@bindable keys: string;

	private _downKeys: string[];

	constructor(private _elem: Element) {
		super("Component-Core-KeySwitch");
		this._downKeys = [];

	}

	//#region -------------------------------------       Framework-Events       -------------------------------------
	attached() {
		super.attached();
		let that = this;
		document.addEventListener('keydown', function (e) { that.keyDown(e); });
		document.addEventListener('keyup', function (e) { that.keyUp(e); });
	}

	detached() {
		super.detached();
		let that = this;
		document.removeEventListener('keydown', function (e) { that.keyDown(e); })
		document.removeEventListener('keyup', function (e) { that.keyUp(e); })
	}
	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	keyDown(e: KeyboardEvent) {
		if (!this._downKeys.includes(e.key.toLowerCase())) {
			this._downKeys.push(e.key.toLowerCase());
			if (this.validateDownKeys()) {
				let contentElements = this._elem.getElementsByTagName('div')[0].children;
				for (var i = 0; i < contentElements.length; i++) {
					(contentElements[i] as HTMLElement).click();
				}
			};
		}
	}

	keyUp(e: KeyboardEvent) {
		this._downKeys.splice(this._downKeys.indexOf(e.key.toLowerCase()), 1);
	}

	validateDownKeys():boolean {
		let validationKeys = this.keys.split(';');
		if (validationKeys.length == this._downKeys.length) {
			let valid: boolean = true;
			for (var i = 0; i < this._downKeys.length; i++) {
				if (!validationKeys.includes(this._downKeys[i])) {
					valid = false;
					break;
				}
			}
			return valid;
		}
		return false
	}
}



