export class Queue<T> {
	private elements: Array<T>;

	constructor() {
		this.elements = new Array<T>();
	}

	enqueue(newElement: T) {
		this.elements.push(newElement);
	}

	dequeue() {
		return this.elements.shift();
	}

	isEmpty() {
		return this.elements.length == 0;
	}

	peek() {
		return !this.isEmpty() ? this.elements[0] : undefined;
	}

	length() {
		return this.elements.length;
	}
}

