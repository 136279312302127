export class Triggers {

	public static debounce(timer, func, timeout = 300) {
		clearTimeout(timer);
		timer = setTimeout(() => { func(); }, timeout);
		return timer;
	}
	
}

