import { JobOfferDetail } from "./JobOfferDetail";

export class EditJobOfferDetail {
	detailType: number;
	de_detail: JobOfferDetail;
	en_detail: JobOfferDetail;

	constructor(iconname: string) {
		this.detailType = 0;
		this.de_detail = new JobOfferDetail(iconname);
		this.en_detail = new JobOfferDetail(iconname);
	}
}