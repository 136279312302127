import { autoinject, DOM } from "aurelia-framework";

import { BaseClass } from '../../core/base-class';
import { IBranche } from "../../interfaces/IBranche";
import { ICompetence } from "../../interfaces/ICompetence";
import { IHistory } from "../../interfaces/IHistory";
import { IJobOffer } from "../../interfaces/IJobOffer";
import { INews } from "../../interfaces/INews";
import { IProduct } from "../../interfaces/IProduct";
import { LoginContext } from '../../models/LoginContext';
import { AuthService } from "../../services/auth-service";
import { LocalesService } from "../../services/locales-service";
import { BrancheService } from "../../services/project/branche-service";
import { CompetenceService } from "../../services/project/competence-service";
import { HistoryService } from "../../services/project/history-service";
import { JobOfferService } from "../../services/project/joboffer-service";
import { PressService } from "../../services/project/press-service";
import { ProductService } from "../../services/project/product-service";

import '@progress/kendo-ui/js/kendo.button.min';
import '@progress/kendo-ui/js/kendo.grid.min';

import './dashboard.scss';

@autoinject()
export class Dashboard extends BaseClass {
	//#region Variables

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	_isLoading = true;
	_selectedDashboard = null;
	_pkWidth = "40px";
	_operationWidth = "300px";

	_historyList: IHistory[] = [];
	_historyDataSource = new kendo.data.DataSource();
	_pressList: INews[] = [];
	_pressDataSource = new kendo.data.DataSource();
	_competenceList: ICompetence[] = [];
	_competenceDataSource = new kendo.data.DataSource();
	_brancheList: IBranche[] = [];
	_brancheDataSource = new kendo.data.DataSource();
	_productList: IProduct[] = [];
	_productDataSource = new kendo.data.DataSource();
	_jobofferList: IJobOffer[] = [];
	_jobofferDataSource = new kendo.data.DataSource();
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext,
				private _authService: AuthService,
				private _historyService: HistoryService,
				private _pressService: PressService,
				private _competenceService: CompetenceService,
				private _brancheService: BrancheService,
				private _productService: ProductService,
				private _jobOfferService: JobOfferService,
				private _localesService: LocalesService) {
		super('View-Dashboard');
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");
	}

	attached() {
		super.attached();

		if (this._urlParams["pointer"] == undefined) {
			var firstLi = document.querySelector("#dashboard-sidemenu li") as HTMLLIElement;
			this.SetDashboard(firstLi.dataset["pointer"]);
			firstLi.classList.add("active");
		} else {
			this.SetDashboard(this._urlParams["pointer"]);
			var liList = document.querySelectorAll("#dashboard-sidemenu li") as NodeListOf<HTMLLIElement>;
			for (var i = 0; i < liList.length; i++) {
				if (liList[i].dataset["pointer"] == this._urlParams["pointer"]) {
					liList[i].classList.add("active");
				}
			}
		}
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	onClick_Sidemenu_Item(target: HTMLLIElement) {
		var liList = document.querySelectorAll("#dashboard-sidemenu li") as NodeListOf<HTMLLIElement>;
		for (var i = 0; i < liList.length; i++) {
			liList[i].classList.remove("active");
		}

		this.redirect("dashboard", { "pointer": target.dataset.pointer });
		target.classList.add("active");
		this.SetDashboard(target.dataset.pointer);
	}

	async onDelete(name, id) {
		var that = this;
		var i18n_name = this._i18n.tr(name + ".title");
		this.dialogconfirm(this._i18n.tr("dashboard.delete_title", { name: i18n_name, id: id }), this._i18n.tr("dashboard.delete_question")).then(function () {
			that.Log_UserInput("deleting confirmed...");
			switch (name) {
				case "history":
					that._historyService.Delete(id).then(result => {
						that.DeleteResult(result, id, i18n_name);
					});
					break;
				case "press":
					that._pressService.Delete(id).then(result => {
						that.DeleteResult(result, id, i18n_name);
					});
					break;
				case "competence":
					that._competenceService.Delete(id).then(result => {
						that.DeleteResult(result, id, i18n_name);
					});
					break;
				case "branche":
					that._brancheService.Delete(id).then(result => {
						that.DeleteResult(result, id, i18n_name);
					});
					break;
				case "product":
					that._productService.Delete(id).then(result => {
						that.DeleteResult(result, id, i18n_name);
					});
					break;
				case "joboffer":
					that._jobOfferService.Delete(id).then(result => {
						that.DeleteResult(result, id, i18n_name);
					});
					break;
				default:
					break;
			}
		}, function () {
			that.Log_UserInput("deleting canceled...");
		});
	}

	async onSwitchVisibility(name, id) {
		var that = this;
		var i18n_name = this._i18n.tr(name + ".title");
		that.Log_UserInput("onSwitchVisibility");
			switch (name) {
				case "joboffer":
					that._jobOfferService.SwitchVisibility(id).then(result => {
						that.SwitchVisibilityResult(result, id, i18n_name);
					});
					break;
				default:
					break;
			}
	}
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	DeleteResult(result, id, i18n_name) {
		if (result == 200) {
			this.SetDashboard(this._selectedDashboard);
			this.AlertLogCodeWithText(result, this._i18n.tr("dashboard.delete_answer_ok", { name: i18n_name, id: id }));
		} else {
			this.AlertLogCodeWithText(result, this._i18n.tr("dashboard.delete_answer_nok", { name: i18n_name, id: id }));
		}
	}

	SwitchVisibilityResult(result:number, id, i18n_name) {
		if (result == 200) {
			this.SetDashboard(this._selectedDashboard);
			//Switch Symbol
			this.AlertLogCodeWithText(result, this._i18n.tr("dashboard.switch_visibility_answer_ok", { name: i18n_name, id: id }));
		} else {
			this.AlertLogCodeWithText(result, this._i18n.tr("dashboard.switch_visibility_answer_nok", { name: i18n_name, id: id }));
		}
	}

	AlertLogCodeWithText(code, text = "") {
		switch (code) {
			case 200:
				this.Alert_Success(text + " - Status: " + code);
				this.Log_Info(text + " - Status: " + code);
				break;
			case 401:
				this.Alert_Error(text + " Authentication Failure - Status: " + code);
				this.Log_Error(text + " Authentication Failure - Status: " + code);
				break;
			default:
				this.Alert_Error(text + " - Status: " + code);
				this.Log_Error(text + " - Status: " + code);
				break;
		}
	}

	SetDashboard(name) {
		this._selectedDashboard = name;
		this._isLoading = true;
		switch (name) {
			case "history":
				this._historyService.GetAll().then(response => {
					this._historyList = response;
					this._historyDataSource.data(this._historyList);
					this._isLoading = false;
				});
				break;
			case "press":
				this._pressService.GetAll().then(response => {
					this._pressList = response;
					this._pressDataSource.data(this._pressList);
					this._isLoading = false;
				});
				break;
			case "competence":
				this._competenceService.GetAll().then(response => {
					this._competenceList = response;
					this._competenceDataSource.data(this._competenceList);
					this._isLoading = false;
				});
				break;
			case "branche":
				this._brancheService.GetAll().then(response => {
					this._brancheList = response;
					this._brancheDataSource.data(this._brancheList);
					this._isLoading = false;
				});
				break;
			case "product":
				this._productService.GetAll().then(response => {
					this._productList = response;
					this._productDataSource.data(this._productList);
					this._isLoading = false;
				});
				break;
			case "joboffer":
				this._jobOfferService.GetAll().then(response => {
					this._jobofferList = response;
					this._jobofferDataSource.data(this._jobofferList);
					this._isLoading = false;
				});
				break;
			default:
				break;
		}
	}

	redirect(newTab, newparams) {
		window.scrollTo(0, 0);
		this._router.navigateToRoute(newTab, newparams);
	}

	logout() {
		this.Log_UserInput("Logout!");
		// when we move out of this page we want it to stop all services/intervals
		this._authService.Logout().then(async response => {
			//redirect to login-page
			this._router.navigateToRoute("login", this._urlParams);
			this.Alert_Info("Sie haben sich ausgeloggt!"); 
		});
	}
    //#endregion Impl 
} 