import { autoinject, DOM } from "aurelia-framework";

import { BaseClass } from '../../core/base-class';
import { ICompetence } from "../../interfaces/ICompetence";
import { LoginContext } from '../../models/LoginContext';
import { CompetenceService } from "../../services/project/competence-service";

import './competencetest.scss';

@autoinject()
export class Competencetest extends BaseClass {
	//#region Variables
	static viewmodel: Competencetest;

	//#region -------------------------------------       Public        -------------------------------------
	competences = [];
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	_viewIndex: number;
	_competencelist: ICompetence[] = [];
	_isLoading: boolean = true;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext,
				private _competenceService: CompetenceService) {
		super('View-Competencetest');

		Competencetest.viewmodel = this;

		_competenceService.GetAll().then((response: ICompetence[]) => {
			this._competencelist = response;
		}).finally(() => {
			this._isLoading = false;
		});
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");
	}
		
	attached() {
		super.attached();

		this.Log_Info("Preload all Competence Images");
		for (var i = 1; i < this.competences.length; i++) {
			this.preloadImage(this.competences[i].imgpath);
		}
	}

	activate(params) {
		this._viewIndex = params.id;
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------

	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	
    //#endregion Impl
}

class CompetenceItem {
	headertext: string;
	bodytext: string;
	imgpath: string;
	imgalt: string;

	constructor(headertext, bodytext, imgpath, imgalt) {
		this.headertext = headertext;
		this.bodytext = bodytext;
		this.imgpath = imgpath;
		this.imgalt = imgalt;
	}
}