import { HistoryTextInput } from "./HistoryTextInput";


export class HistoryInput {
	TextItemList: HistoryTextInput[];
	date: Date;
	imagePath: any;

	constructor(textInputList, date, imagePath) {
		this.TextItemList = textInputList;
		this.date = date;
		this.imagePath = imagePath;
	}
}