import { ProductTextInput } from "./ProductTextInput";


export class ProductInput {
	TextItemList: ProductTextInput[];
	ImagePaths: any[];
	VideoPath: any;
	OrderNumber: number;

	constructor(textInputList) {
		this.TextItemList = textInputList;
		this.ImagePaths = [];
		this.OrderNumber = -1;
	}
}