import { inject, bindable } from 'aurelia-framework';
import { BaseClass } from '../../core/base-class';
import { gsap } from 'gsap';

import './arec-competencesvg.scss';

@inject(Element)
export class ArecCompetencesvgCustomElement extends BaseClass {
	//#region Variables
	//#region -------------------------------------       Bindables        -------------------------------------

	//#endregion -------------------------------------       Bindables        -------------------------------------

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	_tl: gsap.core.Timeline;
	_elem: HTMLElement;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(elem) {
		super('Layout-ArecCompetencesvg');
		this._elem = elem;
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
	}

	detached() {
		super.detached();
		
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	onmouseenter() {
		this.animateSVG();
	}

	onmouseleave() {
		this._tl.restart();
		this._tl.clear();
	}
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	animateSVG() {
		this._tl = gsap.timeline();
		this._tl.to("#competencesvg", { scaleX: 1.05, scaleY: 0.95, duration: 0.25 })
			.to("#competencesvg", { scaleX: 0.95, scaleY: 1.05, duration: 0.25 })
			.to("#competencesvg", { scaleX: 1, scaleY: 1, duration: 0.25 })
			.to("#competence-lightbulb .cls-1", { fill: "#004C8A", duration: 1 });
	}
	//#endregion Impl
}

