import { inject, bindable } from 'aurelia-framework';
import { BaseClass } from '../../core/base-class';

import './arec-card.scss';

@inject(Element)
export class ArecCardCustomElement extends BaseClass {
	@bindable iconclass: string;
	@bindable title: string;
	@bindable subtitle: string;
	@bindable description: string;
	@bindable index: number;
	@bindable slickclass: string;
	@bindable slickchanged: Function;
	@bindable slickimages: string[];
	@bindable isvideo: boolean;
	@bindable viewvideo: boolean;
	//#region Variables
	//#region -------------------------------------       Bindables        -------------------------------------
	//@bindable variable: datatype(boolean,string,...);
	//#endregion -------------------------------------       Bindables        -------------------------------------

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	_elem: HTMLElement;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(elem) {
		super('Layout-ArecCard');
		this._elem = elem;
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
		if (this.slickclass != null) {

			$('.' + this.slickclass).slick({
				centerMode: true,
				dots: true,
				slidesToShow: 1,
				focusOnSelect: true
			});

			var that = this;
			$('.' + this.slickclass).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
				
				//that.collapseSection(that._elem.getElementsByClassName("card-body")[0]);
				that.Publish("slick_slide_changed", [that.index, nextSlide]);
				//that.CallAfterUIUpdate(() => {
				//	that.expandSection(that._elem.getElementsByClassName("card-body")[0]);
				//}, 1200);
			});
		}
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------

	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	
	collapseSection(element) {
		// get the height of the element's inner content, regardless of its actual size
		var sectionHeight = element.scrollHeight;

		// temporarily disable all css transitions
		var elementTransition = element.style.transition;
		element.style.transition = '';

		// on the next frame (as soon as the previous style change has taken effect),
		// explicitly set the element's height to its current pixel height, so we 
		// aren't transitioning out of 'auto'
		requestAnimationFrame(function () {
			element.style.height = sectionHeight + 'px';
			element.style.transition = elementTransition;

			// on the next frame (as soon as the previous style change has taken effect),
			// have the element transition to height: 0
			requestAnimationFrame(function () {
				element.style.height = 0 + 'px';
			});
		});

		// mark the section as "currently collapsed"
		element.setAttribute('data-collapsed', 'true');
	}

	expandSection(element) {
		// get the height of the element's inner content, regardless of its actual size
		var sectionHeight = element.scrollHeight;
		// have the element transition to the height of its inner content
		element.style.height = sectionHeight + 'px';
		// mark the section as "currently not collapsed"
		element.setAttribute('data-collapsed', 'false');
	}

	OnVideo_click() {
		this.Publish("OnClick_ViewVideo", this.index);
	}
	//#endregion Impl
}

