import { inject, bindable } from 'aurelia-framework';
import { BaseClass } from '../../core/base-class';
import { gsap } from 'gsap';

import './arec-branchsvg.scss';

@inject(Element)
export class ArecBranchsvgCustomElement extends BaseClass {
	//#region Variables
	//#region -------------------------------------       Bindables        -------------------------------------

	//#endregion -------------------------------------       Bindables        -------------------------------------

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	_tl: gsap.core.Timeline;
	_elem: HTMLElement;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(elem) {
		super('Layout-ArecBranchsvg');
		this._elem = elem;
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
	}

	detached() {
		super.detached();
		
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	onmouseenter() {
		this.animateSVG();
	}

	onmouseleave() {
		this._tl.restart();
		this._tl.clear();
	}
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	animateSVG() {
		this._tl = gsap.timeline();
		this._tl.to("#branch-box-3", { fill: "#004C8A", rotate: -5, duration: 0.25 })
				.to("#branch-box-3", { fill: "#d5d5d5", rotate: 0, duration: 0.25 })
				.to("#branch-box-2", { fill: "#004C8A", rotate: 5, duration: 0.25 })
				.to("#branch-box-2", { fill: "#d5d5d5", rotate: 0, duration: 0.25 })
				.to("#branch-box-1", { fill: "#004C8A", rotate: -5, duration: 0.25 })
				.to("#branch-box-1", { fill: "#d5d5d5", rotate: 0, duration: 0.25 });
	}
	//#endregion Impl
}

