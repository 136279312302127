import { autoinject, DOM } from "aurelia-framework";
//import { gsap, TimelineMax, Linear, TweenMax } from "gsap";

import { BaseClass } from '../../core/base-class';
import { LoginContext } from '../../models/LoginContext';

import { IHistory } from "../../interfaces/IHistory";
import { HistoryService } from "../../services/project/history-service";

import './history.scss';


@autoinject()
export class History extends BaseClass {
	//#region Variables

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------
	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	_historylist: IHistory[] = [];
	_isLoading: boolean = true;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext,
		private _historyService: HistoryService) {
		super('View-History');

		_historyService.GetAll().then((response: IHistory[]) => {
			this._historylist = response;
			this.CallAfterUIUpdate(() => {
				this.timelineRefresh();
				$(window).scroll();
			}, 1);
			this.updateHistoryTitle();
		}).finally(() => {
			this._isLoading = false;
		});



	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");
	}


	attached() {
		super.attached();

		window.addEventListener('resize', () => {
			this.CallAfterUIUpdate(() => {
				this.timelineRefresh();
				$(window).scroll();
			}, 1);
		});

	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl
	timelineRefresh() {
		var that = $("#timeline-1");
		var selectors = {
			id: that,
			item: that.find(".timeline-item"),
			activeClass: "timeline-item--active",
			img: ".timeline__img"
		};

		//change background
		selectors.item.eq(0).addClass(selectors.activeClass);
		selectors.id.css(
			"background-image",
			"url(" +
			selectors.item
				.first()
				.find(selectors.img)
				.attr("src") +
			")"
		);

		var mq = window.matchMedia("(max-width: 767px)");
		if (mq.matches) {
			// window width is at less than 570px
			$(".timeline-item").each(function (i) {
				this.classList.add(selectors.activeClass);
			});
			$(window).scroll().off();
		}
		else {
			// window width is greater than 570px
			$(".timeline-item").each(function (i) {
				this.classList.remove(selectors.activeClass);
			});

			$(window).scroll(function () {
				var switchingActivePos;
				var windowHeight = window.innerHeight;
				selectors.item.each(function (i) {
					var elementHeight =this.getBoundingClientRect().height;
					var elementPosRelTop = this.getBoundingClientRect().top;
					var elementPosRelBotton = elementPosRelTop + elementHeight;
					switchingActivePos = (windowHeight / 2);
					
					var elementIntersectWithSwitchActivePos = (elementPosRelTop < switchingActivePos) && (switchingActivePos < (elementPosRelBotton));
					this.classList.remove(selectors.activeClass);
					if (elementIntersectWithSwitchActivePos) {
						var src = $(this)
							.find(selectors.img)
							.attr("src");
						if (src.includes('.gif')) {
							selectors.id.css(
								"background-image",
								"none"
							);
						} else {
							selectors.id.css(
								"background-image",
								"url(" + src + ")"
							);
						}
						
						this.classList.add(selectors.activeClass);
						//console.log("  elementPosRelTop: " + elementPosRelTop + "  switchingActivePos: " + switchingActivePos + "  elementPosRelBotton: " + elementPosRelBotton);
					} 
				});
			});
		}
		
	}

	updateHistoryTitle() {
		this._historylist.forEach((historyElement) => {
			historyElement["LanguageTextTitle"] = this._i18n.tr("history.items." + historyElement.ID + ".title");
		});
	}

	onLangChange(id) {
		console.log(this._i18n.getLocale());
		return this._i18n.tr("history.items." + id + ".title");
	}

    //#endregion Impl
}
