import { inject, bindable} from 'aurelia-framework';
import { BaseClass } from '../../core/base-class';
import { CoreAnimationCustomElement } from '../../core/components/animation/core-animation';
import { Dictionary, IDictionary } from '../../core/interfaces/IDictionary';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel";

import './arec-branch-product-viewer.scss';

export class BranchProductItem {
	title: string;
	subtitle: string;
	description: string;
	iconclass: string;
	currentIndex: number = 0;
	id: number;
	images = [];
	video: string = null;
	viewvideo: boolean = false;
	iconlinks = [];

	constructor(id, title, subtitle, description, iconclass, images, iconlinks,video) {
		this.id = id;
		this.title = title;
		this.subtitle = subtitle;
		this.description = description;
		this.iconclass = iconclass;
		this.images = images;
		this.video = video;
		this.viewvideo = false;
		this.iconlinks = iconlinks;
	}
}

@inject(Element)
export class ArecBranchProductViewerCustomElement extends BaseClass {
	//#region Variables
	//#region -------------------------------------       Bindables        -------------------------------------
	@bindable source: BranchProductItem[];
	@bindable isloading: boolean = false;
	@bindable headingkey: string;

	//#endregion -------------------------------------       Bindables        -------------------------------------

	//#region -------------------------------------       Public        -------------------------------------
	
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	private images_top: BranchProductItem[] = [];
	private images_bottom: BranchProductItem[] = [];
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(elem) {
		super('Layout-ArecBranchProductViewer');
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();

		this.Sub("slick_slide_changed", (data) => { this.onSlickChanged(this, data[0], data[1]) });
		this.Sub("OnClick_ViewVideo", (index) => {
			this.source[index].viewvideo = !this.source[index].viewvideo;

			if (window.innerWidth < 769) {
				//fullscreen only under 769px
				var that = this;
				this.CallAfterUIUpdate(() => {
					$("#video-" + index).bind('webkitfullscreenchange mozfullscreenchange fullscreenchange', function (e) {
						var isFullscreen = document["fullScreen"] || document["mozFullScreen"] || document["webkitIsFullScreen"];
						if (!isFullscreen) {
							that.source[index].viewvideo = false;
						}
					});
					document.getElementById("video-" + index).requestFullscreen();
				});
			}
		});
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------

	onMouseenterSlide(id) {
		if (window.innerWidth > 768) {
			let viewmodel = (<any>document.getElementById(id)).au.controller.viewModel as CoreAnimationCustomElement;
			viewmodel.IsIntersecting();
		}
	}

	onMouseleaveSlide(id) {
		if (window.innerWidth > 768) {
			let viewmodel = (<any>document.getElementById(id)).au.controller.viewModel as CoreAnimationCustomElement;
			viewmodel.IsNotIntersecting();
		}
	}

	onSlickChanged(that: ArecBranchProductViewerCustomElement, index, currentSlide) {
		that.Log_UserInput("User Changed Slick Index: " + index);
		that.source[index].currentIndex = currentSlide;

		var anchor_elem = document.getElementById("anchor_" + index) as HTMLElement;
		anchor_elem.classList.remove("animate");
		anchor_elem.offsetWidth;
		anchor_elem.classList.add("animate");

		that.source[index].viewvideo = false;
	}

	scroll(id, behavior: ScrollBehavior = "smooth") {
		//solution cause of problems with bounce scrolling
		document.querySelectorAll(".l-accordion").forEach((item: HTMLElement) => {
			item.style.flex = "1";
		});
		this.scrollToElementById(id, behavior);
		this.CallAfterUIUpdate(() => {
			document.querySelectorAll(".l-accordion").forEach((item: HTMLElement) => {
				item.removeAttribute("style");
			});
		}, 1000);
	}

	queryParentElement(el: HTMLElement | null, selector: string) {
		let isIDSelector = selector.indexOf("#") === 0
		if (selector.indexOf('.') === 0 || selector.indexOf('#') === 0) {
			selector = selector.slice(1)
		}
		while (el) {
			if (isIDSelector) {
				if (el.id === selector) {
					return el
				}
			}
			else if (el.classList.contains(selector)) {
				return el;
			}
			el = el.parentElement;
		}
		return null;
	}

	sourceChanged(newValue, oldValue) {
		var topLength = Math.ceil(this.source.length / 2);
		for (var i = 0; i < topLength; i++) {
			this.images_top.push(this.source[i]);
		}

		for (var i = topLength; i < this.source.length; i++) {
			this.images_bottom.push(this.source[i]);
		}

		if (this._urlParams['id']) {
			var found = null;
			for (var i = 0; i < this.source.length; i++) {
				if (Number.parseInt(this._urlParams['id']) == this.source[i].id) {
					found = i;
					break;
				}
			}

			if (found != null) {
				this.CallAfterUIUpdate(() => {
					this.scroll("anchor_" + found, "auto");
				});
			}
		}
	}
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	
	//#endregion Impl
}

