
export class PressTextInput {
	title: string;
	description: string;
	language: string;

	constructor(language, title = null, description = null) {
		this.language = language;
		this.title = title;
		this.description = description;
	}
}