
export class BrancheTextInput {
	title: string;
	subtitle: string;
	description: string;
	language: string;

	constructor(language, title = null, subtitle = null, description = null) {
		this.language = language;
		this.title = title;
		this.subtitle = subtitle;
		this.description = description;
	}
}