import { inject, bindable } from 'aurelia-framework';
import { BaseClass } from '../../base-class';
import { StringHelper } from '../../helper_classes/stringhelper/stringhelper';

@inject(Element)
export class CoreAnimationCustomElement extends BaseClass {
	//#region Variables
	//#region -------------------------------------       Bindables        -------------------------------------
	@bindable initclass: string;
	@bindable selfcontrol: boolean = false;
	@bindable firsttimetrigger: boolean = false;
	@bindable intersectedclass: string;
	@bindable notintersectedclass: string;
	@bindable observerpercent: number = 0.5;
	@bindable animationdelay: string = "0s";
	@bindable stay: boolean = false;
    //#endregion -------------------------------------       Bindables        -------------------------------------

	//#region -------------------------------------       Public        -------------------------------------

	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	_elem: Element
	_observer: IntersectionObserver;
	_init: boolean = false;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(elem) {
		super("Component-Core-Animation");

		this._elem = elem;
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	attached() {
		super.attached();

		if (!this.selfcontrol) {
			//options of observer
			let options: IntersectionObserverInit = {
				threshold: (this.observerpercent / 100)
			};
			//intersection observer
			this._observer = new IntersectionObserver((entries, observer) => {
				this.callbackFunc(entries[0].isIntersecting)
			}, options);

			var childs = this._elem.getElementsByTagName("*");
			for (var i = 0; i < childs.length; i++) {
				if (childs[i].getAttributeNames().indexOf("slot") != -1) {
					this._observer.observe(childs[i]);
					break;
				}
			}
		}

		if (this.initclass != null) {
			this.initclass.split(" ").forEach((classname) => {
				var elems = this._elem.getElementsByClassName("toanimate");
				for (var i = 0; i < elems.length; i++) {
					elems[i].classList.add(classname);
				}
			});
		}

		var elems = this._elem.getElementsByClassName("toanimate");
		for (var i = 0; i < elems.length; i++) {
			var styles = elems[i].getAttribute("style");
			if (StringHelper.IsNullOrEmpty(styles)) {
				elems[i].setAttribute("style", "animation-delay: " + this.animationdelay);
			} else {
				elems[i].setAttribute("style", styles + ";animation-delay: " + this.animationdelay);
			}
		}
		
		
	}

	detached() {
		super.detached();
	}
	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------

	public IsIntersecting() {
		this.callbackFunc(true);
	}

	public IsNotIntersecting() {
		this.callbackFunc(false);
	}

	callbackFunc(isIntersecting) {
		var elems = this._elem.getElementsByClassName("toanimate");
		for (var i = 0; i < elems.length; i++) {
			if (this._init || this.selfcontrol || this.firsttimetrigger) {
				if (isIntersecting) {
					if (this.intersectedclass != null) {
						elems[i].classList.add(this.intersectedclass);
					}
					if (this.notintersectedclass != null) {
						elems[i].classList.remove(this.notintersectedclass);
					}

					if (this.stay) {
						this._observer.disconnect();
					}
				} else {
					if (this.intersectedclass != null) {
						elems[i].classList.remove(this.intersectedclass);
					}
					if (this.notintersectedclass != null) {
						elems[i].classList.add(this.notintersectedclass);
					}
				}
			}
		}

		this._init = true;
	}
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	//#endregion Impl
}