import { TaskQueue, autoinject, DOM } from "aurelia-framework";
import { ready, newInstance, BrowserJsPlumbInstance } from "@jsplumb/browser-ui"

import { BaseClass } from '../../../core/base-class';
import { LoginContext } from '../../../models/LoginContext';
import { ProductInput } from "../../../models/ProductInput";
import { ProductTextInput } from "../../../models/ProductTextInput";
import { ProductService } from "../../../services/project/product-service";
import { BrancheService } from "../../../services/project/branche-service";
import { IBranche } from "../../../interfaces/IBranche";
import { JSPlumbRelation } from "../../../models/JSPlumb_Relation";

import './edit.scss';

@autoinject()
export class Edit extends BaseClass {
	//#region Variables
	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	private _indexOfEditedObj = null;
	private _productTextInput: ProductTextInput[];
	private _productInput: ProductInput;
	private _branches: IBranche[];
	private _relProductBranche: JSPlumbRelation[] = [];
	private _isLoading: boolean = true;
	private _pictureList: string[];
	private _selectedServerPictureList: any[] = [];
	private _selectedClientPictureList: any[] = [];
	private _videoList: string[];
	private _selectedServerVideoList: any[] = [];
	private _selectedClientVideoList: any[] = [];
	private _jsplumb: BrowserJsPlumbInstance;
	
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext,
				private _taskQueue: TaskQueue,
				private _productService: ProductService,
				private _brancheService: BrancheService) {
		super('View-Edit_Product');

	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");
		this._productService.GetPictureList().then((response) => {
			this._pictureList = response;
		});
		this._productService.GetVideoList().then((response) => {
			this._videoList = response;
		});

		var that = this;
		window.addEventListener("resize", function (e) {
			that._jsplumb.repaintEverything();
		});
	}

	activate(params) {
		this._indexOfEditedObj = params.id;

		this._productTextInput = [];
		this._productTextInput.push(new ProductTextInput("de"));
		this._productTextInput.push(new ProductTextInput("en"));
		this._productInput = new ProductInput(this._productTextInput);
		//if '_indexOfJobOffer' has an value, the user will edit an existing JobOffer
		if (this._indexOfEditedObj != null) {
			this._isLoading = true;
			this._productService.Get(this._indexOfEditedObj).then((resp) => {
				var imagesArr = JSON.parse(resp.JsonImagePaths);
				//adding selected image to imagepicker and select the right image
				for (var i = 0; i < imagesArr.length; i++) {
					this._productInput.ImagePaths.push({
						index: i+1,
						type: "server",
						data: imagesArr[i].substring(imagesArr[i].lastIndexOf("/") + 1)
					});
				}
				this._selectedServerPictureList = this._productInput.ImagePaths;
				this._productInput.VideoPath = resp.VideoPath;
				if (resp.VideoPath != null) {
					//adding selected video to imagepicker and select the right video
					this._selectedServerVideoList.push({
						index: 1,
						type: "server",
						data: this._productInput.VideoPath.substring(this._productInput.VideoPath.lastIndexOf("/") + 1)
					});
				}
				this._productInput.OrderNumber = resp.OrderNumber;
				//store current translation language
				var oldLocale = this._i18n.getLocale();
				for (var i = 0; i < this._productInput.TextItemList.length; i++) {
					var lang = this._productInput.TextItemList[i].language;
					// translation language
					this._i18n.setLocale(lang);
					//set title
					this._productInput.TextItemList[i].title = this._i18n.tr("product.items." + resp.ID + ".title");
					//set subtitle
					this._productInput.TextItemList[i].subtitle = this._i18n.tr("product.items." + resp.ID + ".subtitle");
					//set description
					this._productInput.TextItemList[i].description = this._i18n.tr("product.items." + resp.ID + ".description");
				}
				for (var i = 0; i < resp.Branches.length; i++) {
					this._relProductBranche.push(new JSPlumbRelation(resp.ID, resp.Branches[i].BrancheID))
				}
				//set old translation language
				this._i18n.setLocale(oldLocale);
				this.loadBrancheList();
			});
		} else {
			this.loadBrancheList();
		}
	}

	attached() {
		super.attached();
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  

	private loadBrancheList() {
		this._brancheService.GetAll().then((resp) => {
			this._branches = resp;
			this._isLoading = false;
			this.CallAfterUIUpdate(() => {
				this.jsPlumb_Ready();
			}, 500);
		});
	}

	jsPlumb_Ready() {
		//jsplumb ready
		ready(() => {
			//register default styling and settings
			this._jsplumb = newInstance({
				container: document.getElementById("jsPlumbContainer"),
			})

			this._jsplumb.registerConnectionTypes({
				"default": {
					paintStyle: { stroke: "#004C8A", strokeWidth: 4},
					hoverPaintStyle: { stroke: "#98B7D4", strokeWidth: 5 },
					cssClass: "connector-normal"
				}
			});

			var sourceEP: any = { anchor: "Left"};
			var targetEP: any = { anchor: "Right", maxConnections: 1};
			var common = {
				paintStyle: { fill: "#004C8A"},
				endpoint: "Rectangle",
				detachable: true,
				connectorPaintStyle: { type:"basic" }
			};
			//define the source and target elements
			this._jsplumb.makeSource(document.getElementById("jsP_source_product"), sourceEP, common);
			for (var i = 0; i < this._branches.length; i++) {
				this._jsplumb.makeTarget(document.getElementById("jsP_target_branche_" + this._branches[i].ID), targetEP, common);
			}
			//init all connections/relationships
			for (var i = 0; i < this._relProductBranche.length; i++) {
				var con = this._jsplumb.connect({
					target: document.getElementById("jsP_target_branche_" + this._relProductBranche[i].target),
					source: document.getElementById("jsP_source_product")
				});

				this._relProductBranche[i].conID = con.id;
			}
			//bind to connection and detach events to handle the state
			var that = this;
			this._jsplumb.bind("connection", function (info) {
				var split_Target = info.target.id.split('_')
				var targetID = Number.parseInt(split_Target[3]);
				if (split_Target[2] == "branche") {
					that.checkUniqueAndInsertIfUnique(that._relProductBranche, targetID, info.connection, that._jsplumb);
				}
			});

			this._jsplumb.bind("beforeDetach", function (connection) {
				var index = null;
				for (var i = 0; i < that._relProductBranche.length; i++) {
					if (that._relProductBranche[i].conID == connection.id) {
						index = i;
						break;
					}
				}
				if (index != null) {
					that._relProductBranche.splice(index, 1);
				}
			});
		})
	}

	checkUniqueAndInsertIfUnique(relationList, targetID, connection, jsPlumbInstance) {
		var unique = true;
		for (var i = 0; i < relationList.length; i++) {
			if (relationList[i].target == targetID) {
				unique = false;
				break;
			}
		}
		if (unique) {
			relationList.push(new JSPlumbRelation(this._indexOfEditedObj ? Number.parseInt(this._indexOfEditedObj) : null, targetID, connection.id));
		} else {
			this.removeConnection(jsPlumbInstance, connection);
		}
	}

	removeConnection(instance, connection) {
		instance.destroyConnection(connection);
		for (var i = connection.endpoints.length - 1; i >= 0; i--) {
			instance.destroyEndpoint(connection.endpoints[i]);
		}
	}

	redirect(newTab, newparams) {
		window.scrollTo(0, 0);
		this._router.navigateToRoute(newTab, newparams);
	}

	create() {
		var result = this.checkDataAndGenerateFormData();
		if (result != null) {
			this._productService.Create(result).then((result) => {
				if (result) this.redirect("dashboard", { "pointer": "product" });
			});
		}
	}

	update() {
		var result = this.checkDataAndGenerateFormData();
		if (result != null) {
			this._productService.Update(this._indexOfEditedObj, result).then((result) => {
				if (result) this.redirect("dashboard", { "pointer": "product" });
			});
		}
	}

	checkDataAndGenerateFormData() {
		//store new Product
		var newFormData = new FormData();
		//images
		for (var i = 0; i < this._selectedClientPictureList.length; i++) {
			newFormData.append("rawclientfiles", this._selectedClientPictureList[i].data);
		}
		newFormData.append("clientfilesJSON", JSON.stringify(this._selectedClientPictureList));
		newFormData.append("serverfilesJSON", JSON.stringify(this._selectedServerPictureList));
		//video
		for (var i = 0; i < this._selectedClientVideoList.length; i++) {
			newFormData.append("rawclientvideos", this._selectedClientVideoList[i].data);
		}
		newFormData.append("clientvideosJSON", JSON.stringify(this._selectedClientVideoList));
		newFormData.append("servervideosJSON", JSON.stringify(this._selectedServerVideoList));
		//data
		newFormData.append("input", JSON.stringify(this._productInput));
		newFormData.append("relbrancheproductJSON", JSON.stringify(this._relProductBranche));
		return newFormData;
	}
    //#endregion Impl
}


