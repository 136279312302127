import { autoinject, DOM } from "aurelia-framework";
import { BaseClass } from '../../core/base-class';
import { LoginContext } from '../../models/LoginContext';

import './support.scss';

@autoinject()
export class Support extends BaseClass {
	//#region Variables

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	times = [];
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext) {
		super('View-Support');

		this.times.push(new Time(1,"support.mo", "08:00", "12:00", "13:00", "16:30"));
		this.times.push(new Time(2, "support.di", "08:00", "12:00", "13:00", "16:30"));
		this.times.push(new Time(3, "support.mi", "08:00", "12:00", "13:00", "16:30"));
		this.times.push(new Time(4, "support.do", "08:00", "12:00", "13:00", "16:30"));
		this.times.push(new Time(5, "support.fr", "08:00", "13:00"));

		let now = new Date();
		let currentDay = now.getDay();
		for (var i = 0; i < this.times.length; i++) {
			if (this.times[i].index == currentDay) {
				this.times[i].classname = "currentday";
				break;
			}
		}
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");
	}
	
	attached() {
		super.attached();
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  

    //#endregion Impl
}

class Time {
	index: number;
	dayname: string;
	classname: string;
	time1from: string;
	time1to: string;
	time2from: string;
	time2to: string;

	constructor(index, dayname, time1from, time1to, time2from = null, time2to = null) {
		this.index = index;
		this.dayname = dayname;
		this.time1from = time1from;
		this.time1to = time1to;
		this.time2from = time2from;
		this.time2to = time2to;
	}
}