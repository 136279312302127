import { inject, bindable } from 'aurelia-framework';
import { BaseClass } from '../../core/base-class';

import './arec-icon-sm.scss';

@inject(Element)
export class ArecIconSmCustomElement extends BaseClass {
	//#region Variables
	//#region -------------------------------------       Bindables        -------------------------------------
	@bindable type: string;
	@bindable text: string;
	@bindable href: string;

	//#endregion -------------------------------------       Bindables        -------------------------------------

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	_elem: HTMLElement;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(elem) {
		super('Layout-ArecIconSm');
		this._elem = elem;
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
	}

	detached() {
		super.detached();
		
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------

	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	//#endregion Impl
}

