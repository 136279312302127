import { TaskQueue, autoinject } from "aurelia-framework";

import { BaseClass } from '../../core/base-class';
import { AuthService } from "../../services/auth-service";

@autoinject()
export class MenuWindowCustomElement extends BaseClass {
	//#region Variables
	//#region -------------------------------------       Bindables        -------------------------------------
	//#endregion -------------------------------------       Bindables        -------------------------------------

	//#region -------------------------------------       Public        -------------------------------------
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	//settings menuwindow 
	private _menuwindow;
	private _menuActions = ['Close'];
	//#endregion -------------------------------------       Private       -------------------------------------
	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _taskQueue: TaskQueue,
				private _authService: AuthService) {
		super('Component-MenuWindow');
	}

	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();

		this._taskQueue.queueTask(() => {
			this._menuwindow.center().close();
		});

		this.Sub("Home_openMenu", () => this.OnHome_openMenu());
		this.Sub("Menu_logout", () => this.OnMenu_logout());
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------

	private onMenuReady(menuwindow) {
		this._menuwindow = menuwindow;
		this._menuwindow.close();
	}

	private OnHome_openMenu() {
		if (this._menuwindow.options.visible) {
			this._menuwindow.close();
		} else {
			this._menuwindow.center();
			this._menuwindow.open();
		}
	}

	private OnMenu_logout() {
		this.Log_Warn("[onHome_logout] refresh, no credentials or too long login Trigger");
		this.logout();
	}
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  
	

	//for logout of the Authenticated User
	private logout() {
		this.Log_UserInput("Logout!");
		// when we move out of this page we want it to stop all services/intervals
		this._authService.Logout().then(response => {
			//redirect to login-page
			this._router.navigateToRoute("login", this._urlParams);
			this.Alert_Info("Sie haben sich ausgeloggt!");
		});
	}
	//#endregion Impl
}

