export class DateHelperClass {

    public static getTimeBetweenTwoDates(endtime: string, starttime: string = null): any {
        if (starttime == null) {
            var t = Date.parse(endtime) - Date.parse(new Date().toString());
        } else {
            var t = Date.parse(endtime) - Date.parse(starttime);
        }

        var seconds = Math.floor((t / 1000) % 60);
        var minutes = Math.floor((t / 1000 / 60) % 60);
        var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
        var days = Math.floor(t / (1000 * 60 * 60 * 24));
        return {
            'total': t,
            'days': days,
            'hours': hours,
            'minutes': minutes,
            'seconds': seconds
        };
    }

}
