import { autoinject } from 'aurelia-dependency-injection';
import { BaseClass } from '../core/base-class';

import './master-footer.scss';

@autoinject()
export class MasterFooterCustomElement extends BaseClass {
    //#region Variables
    //#region -------------------------------------       Bindables        -------------------------------------
    //@bindable variable: datatype(boolean,string,...);
    //#endregion -------------------------------------       Bindables        -------------------------------------

    //#region -------------------------------------       Public        -------------------------------------
    //#endregion -------------------------------------       Public        -------------------------------------

    //#region -------------------------------------       Protected     -------------------------------------
    //#endregion -------------------------------------       Protected     -------------------------------------

    //#region -------------------------------------       Private       -------------------------------------
	private currentyear: string;
    //#endregion -------------------------------------       Private       -------------------------------------

    //#endregion Variables

	//#region CTOR / INITIALIZE
	constructor() {
		super('Layout-MasterFooter');
		this.currentyear = new Date(Date.now()).getFullYear().toString();
    }
    //#endregion CTOR / INITIALIZE

    //#region Events   

    //#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
    }

	detached() {
		super.detached();
	}

    //#endregion -------------------------------------       Framework-Events       -------------------------------------

    //#region -------------------------------------       Custom-Events       -------------------------------------
	redirect(newTab) {
		window.scrollTo(0, 0);
		this._router.navigateToRoute(newTab, this._urlParams);
	}
    //#endregion -------------------------------------       Custom-Events       -------------------------------------

    //#endregion Events

	//#region Impl  
    //#endregion Impl
}

