import { IAlert } from '../../interfaces/IAlert'

export class AlertError implements IAlert{
	//#region Variables

	//#region -------------------------------------       Public        -------------------------------------
	get message() {
		return this._message;
	}

	get type() {
		return this._type;
	}

	get autoHideAfter_duration() {
		return this._duration;
	}
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	private _message: string;
	private _type: string;
	private _duration: number;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(msg: string, duration: number) {
		this._message = msg;
		this._type = "error";
		this._duration = duration;
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#endregion Events

	//#region Impl  

	//#endregion Impl
}

export class AlertWarning implements IAlert {
	//#region Variables

	//#region -------------------------------------       Public        -------------------------------------
	get message() {
		return this._message;
	}

	get type() {
		return this._type;
	}

	get autoHideAfter_duration() {
		return this._duration;
	}
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	private _message: string;
	private _type: string;
	private _duration: number;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(msg: string, duration: number) {
		this._message = msg;
		this._type = "warning";
		this._duration = duration;
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#endregion Events

	//#region Impl  

	//#endregion Impl
}

export class AlertInfo implements IAlert {
	//#region Variables

	//#region -------------------------------------       Public        -------------------------------------
	get message() {
		return this._message;
	}

	get type() {
		return this._type;
	}

	get autoHideAfter_duration() {
		return this._duration;
	}
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	private _message: string;
	private _type: string;
	private _duration: number;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(msg: string, duration: number) {
		this._message = msg;
		this._type = "info";
		this._duration = duration;
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#endregion Events

	//#region Impl  

	//#endregion Impl
}

export class AlertSuccess implements IAlert {
	//#region Variables

	//#region -------------------------------------       Public        -------------------------------------
	get message() {
		return this._message;
	}

	get type() {
		return this._type;
	}

	get autoHideAfter_duration() {
		return this._duration;
	}
	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	private _message: string;
	private _type: string;
	private _duration: number;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(msg: string, duration: number) {
		this._message = msg;
		this._type = "success";
		this._duration = duration;
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#endregion Events

	//#region Impl  

	//#endregion Impl
}