
export class CompetenceTextInput {
	title: string;
	description: string;
	details: string[];
	language: string;

	constructor(language, title = null, description = null) {
		this.language = language;
		this.title = title;
		this.details = [];
		this.description = description;
	}
}