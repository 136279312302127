import { autoinject, DOM } from "aurelia-framework";

import { BranchProductItem } from "../../components/arec-branch-product-viewer/arec-branch-product-viewer";
import { BaseClass } from '../../core/base-class';
import { IBranche } from "../../interfaces/IBranche";
import { LoginContext } from '../../models/LoginContext';
import { BrancheService } from "../../services/project/branche-service";

import './branche.scss';

@autoinject()
export class Branche extends BaseClass {
	//#region Variables
	//#region -------------------------------------       Public        -------------------------------------

	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	_brancheList: IBranche[] = []
	_brancheProductList: BranchProductItem[] = []
	_isLoading: boolean = true;
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor(private _loginContext: LoginContext,
				private _brancheService: BrancheService) {
		super('View-Branche');

		_brancheService.GetAll().then((response: IBranche[]) => {
			this._brancheList = response;
			let tmpList: BranchProductItem[] = [];
			for (var branche of this._brancheList) {
				var iconlinks = [];
				var competencearr = [];
				branche.Competences.forEach((item) => {
					competencearr.push({ id: item.CompetenceID, link: "/#/competence/" + item.CompetenceID });
				});
				iconlinks.push({ type: "competence", data: competencearr });
				var productarr = [];
				branche.Products.forEach((item) => {
					productarr.push({ id: item.ProductID, link: "/#/product?id=" + item.ProductID });
				});
				iconlinks.push({ type: "product", data: productarr });

				var tmp = new BranchProductItem(
					branche.ID,
					"branche.items." + branche.ID + ".title",
					branche.Subtitle ? "branche.items." + branche.ID + ".subtitle" : null,
					"branche.items." + branche.ID + ".description",
					null,
					JSON.parse(branche.JsonImagePaths) as string[],
					iconlinks,
					branche.VideoPath
				);
				tmpList.push(tmp);
			}
			this._brancheProductList = tmpList;
		}).finally(() => {
			this._isLoading = false;
		});
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	async canActivate() {
		this.Log_Info("Starting Services...");
	}
		

	attached() {
		super.attached();
	}

	detached() {
		super.detached();
	}

	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  

    //#endregion Impl
}
