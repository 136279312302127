export class JobOfferDetail {
	title: string;
	subtitle: string;
	iconclass: string;

	constructor(iconclass = "", title = "", subtitle = "") {
		this.title = title;
		this.subtitle = subtitle;
		this.iconclass = iconclass;
	}
}