export class ConsoleAppender {
    /**
    * Appends a debug log.
    *
    * @param logger The source logger.
    * @param rest The data to log.
    */
    debug(logger: { id: string }, ...rest: any[]): void {
        console.debug(`DEBUG [${logger.id}]`, ...rest);
    }

    /**
    * Appends an info log.
    *
    * @param logger The source logger.
    * @param rest The data to log.
    */
    info(logger: { id: string }, ...rest: any[]): void {
        var args = [...rest];
        var logType = args[0];
        args.splice(0, 1);

        switch (logType) {
            case 0:
                console.info(`INFO [${logger.id}]`, ...args);
                break;
            case 1:

                var styles = [
                    'background: rgba(8,178,19,0.1)'
                    , 'border: 1px solid #08B213'
                    , 'display: block'
					, 'padding: 2px'
                ].join(';');

                console.info(`%cUSERINPUT [${logger.id}]`, styles, ...args);
                break;
            default:
                break;
        }
    }

    /**
    * Appends a warning log.
    *
    * @param logger The source logger.
    * @param rest The data to log.
    */
    warn(logger: { id: string }, ...rest: any[]): void {
        console.warn(`WARN [${logger.id}]`, ...rest);
    }

    /**
    * Appends an error log.
    *
    * @param logger The source logger.
    * @param rest The data to log.
    */
    error(logger: { id: string }, ...rest: any[]): void {
        console.error(`ERROR [${logger.id}]`, ...rest);
    }
}
