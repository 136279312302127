import { autoinject, bindable } from 'aurelia-framework';

import { BaseClass } from '../../base-class';

@autoinject()
export class CoreUserinfoCustomElement extends BaseClass {
	//#region Variables

	//#region -------------------------------------       Bindables        -------------------------------------
    //#endregion -------------------------------------       Bindables        -------------------------------------

	//#region -------------------------------------       Public        -------------------------------------

	//#endregion -------------------------------------       Public        -------------------------------------

	//#region -------------------------------------       Protected     -------------------------------------
	//#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
	//#endregion -------------------------------------       Private       -------------------------------------

	//#endregion Variables

	//#region CTOR / INITIALIZE
	constructor() {
		super("Component-Core-Userinfo");
	}
	//#endregion CTOR / INITIALIZE

	//#region Events   

	//#region -------------------------------------       Framework-Events       -------------------------------------
	attached() {
		super.attached();
	}

	detached() {
		super.detached();
	}
	//#endregion -------------------------------------       Framework-Events       -------------------------------------

	//#region -------------------------------------       Custom-Events       -------------------------------------
	//#endregion -------------------------------------       Custom-Events       -------------------------------------

	//#endregion Events

	//#region Impl  

	//#endregion Impl
}