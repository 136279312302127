import { autoinject, singleton } from "aurelia-framework";

import { BaseClass } from '../core/base-class';
import { LoginContext } from '../models/LoginContext';
import { CommunicationService } from "./comm-service";

@singleton()
@autoinject()
export class AuthService extends BaseClass {
    //#region Variables

    //#region -------------------------------------       Public        -------------------------------------

    //#endregion -------------------------------------       Public        -------------------------------------

    //#region -------------------------------------       Protected     -------------------------------------
    //#endregion -------------------------------------       Protected     -------------------------------------

	//#region -------------------------------------       Private       -------------------------------------
// Info @Thomas : Use IoC from Aurelia in constructor with @autoinject and with parameters in constructor. No need for private variable in this case....!
	//private _httpClient: HttpClient;
    //#endregion -------------------------------------       Private       -------------------------------------

    //#endregion Variables

    //#region CTOR / INITIALIZE
	constructor(private _commuService: CommunicationService,
				private _loginContext: LoginContext) {
		super('Service-Auth');

		//this._httpClient = new HttpClient();
    }
    //#endregion CTOR / INITIALIZE

    //#region Events   

    //#region -------------------------------------       Framework-Events       -------------------------------------

	attached() {
		super.attached();
    }

	detached() {
		super.detached();
    }

    //#endregion -------------------------------------       Framework-Events       -------------------------------------

    //#region -------------------------------------       Custom-Events       -------------------------------------

    //#endregion -------------------------------------       Custom-Events       -------------------------------------

    //#endregion Events

    //#region Impl  
	public IsLoggedIn(): boolean{
		return this._loginContext.token != null;
	}

	public async Login(username: string, password: string): Promise<number> {
		let response = await this._commuService.postWithResponse('api/account/login', {
				username: username,
				password: password
			},null, true);
		switch (response.status) {
			case 200:
				let data = await response.json();
				this._loginContext.username = data['username']
				this._loginContext.token = data['token'];
				this.Log_Info("[Login] success: Username: " + data['username']);
				//return true;
			case 404:
				this.Log_Error("[Login] failed: User and Password was wrong!");
				break;
			case 409:
				console.log(409);
				this.GetLoggedInUser().then(async user => {
					console.log(user);
					if (user === "") {
						user = "Unknown";
					}
					console.log(user);
					this.Alert_Error("Es ist bereits " + user + " eingeloggt!");
					this.Log_Error("[Login] failed: Editing is locked from " + user + "!");
				});
				break;
			default:
				this.Log_Error("[Login] failed: [status - "+ response.status + "]" + response.statusText);
				break;
		}
		return response.status;
		//return false;
    }

	public async Logout(): Promise<boolean> {

		let response = await this._commuService.postWithResponse('api/account/logout', null, this._loginContext.token);

		switch (response.status) {
			case 200:
				this._loginContext.token = null;
				//sessionStorage.clear();
				this.Log_Info("[Logout] success");
				return true;
			default:
				this.Log_Error("[Logout] failed: " + response.statusText);
				break;
		}

		return false;
	}

	public async GetLoggedInUser(): Promise<string> {
		console.log("getLoggedInUser()");
		let response = await this._commuService.getResponse('api/account/getLoggedInUser');

		console.log(response);
		//return response;

		switch (response.status) {
			case 200:
				let data = JSON.parse(JSON.stringify(await response.json())) as string;
				return data;
			default:
				this.Log_Error("[getLoggedInUser] failed: [status - " + response.status + "]");
				break;
		}
		return null;
	}

    //#endregion Impl
}